import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  TextField,
  NativeSelect,
  Grid,
  Checkbox,
  FormControlLabel,
  InputLabel,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { userService } from "../services/user_service";
import { validateShowResponseToast } from "../helpers/toast_alerts";
import CustomDatePicker from "../default/custom/CustomDatePicker";
import { colors } from "../constants/dc_constants";
import { hostsABNT } from "../constants/headRows"
import { GestureRounded } from "@material-ui/icons";
import { concessionariaService } from "../services/concessionaria_service";
const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    minHeigth: 600,
    maxWidth: 775,
    minWidth: 775,
  },
  formControl: {
    minWidth: "220px",
  },
  container: {
    marginTop: "15px",
  },
});

class FormProdutor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      //universais
      id: 0,
      usuario_id: "",
      consultor_id: null,
      id_equipamento: "",
      fazenda: "",
      lat: "",
      long: "",
      regiao: "",
      equipamento: "ABNT",
      potencia_transf: null,
      potencia_instalada: null,
      soma_kwa: null,
      nome_bombeamento: "",
      intermediante: "",
      status: 1,
      demanda_contratada: null,
      produto: "power",
      gateway: "ABS",
      energia_injetada: false,
      demanda_geracao: null,
      potencia_usina: null,
      concessionaria_id: null,
      date_account: new Date(),
      usuarios: [],
      concessionarias: [],
      
      //MEDIÇÃO 
      
      //ABB
      tensao_corrente: null,
      transf_potencia1: null,
      transf_potencia2: null,
      soma_cargas: null,
      slave_id: null,
      horario_ponta: false,
      horario_ponta_inicio: "",
      horario_ponta_duracao: "03:00:00",
      horario_reservado: false,
      horario_reservado_inicio: "21:30:00",
      horario_reservado_fim: "06:00:00",
      //ABNT
      relacao_tp: null,
      relacao_tc: null,
      constante_eletronica: null,

      //CONEXÃO

      //Mardey
      codigo_esn: null,
      //ABS
      host_equip: hostsABNT[0],
      host_port: null,
      connection: "",
    };

    this.handleChange = this.handleChange.bind(this);
  }

  getUsuarios = () => {
    userService.getUsuarios({}, (response) => {
      if (validateShowResponseToast(response, "", false)) {
        this.setState({
          usuarios: response.data,
        });
      }
    });
  };

  getConcessionarias = () =>{
    concessionariaService.getConcessionaria((response)=>{
      if (validateShowResponseToast(response, "", false)){
        this.setState({
          concessionarias: response.data,
        })
      }
    })
  }

  componentDidMount() {
    const data = this.props.data;
    if (data) {
      const checkHorarioPonta = data?.horario_ponta_inicio ? true : false;
      const checkInjetada = data?.demanda_geracao || data?.potencia_usina ? true : false;
      this.setState({
        ...data,
        horario_ponta: checkHorarioPonta,
        energia_injetada: checkInjetada,
        host_equip: data.host_equip ?? hostsABNT[0],
      });
    }
    this.getUsuarios();
    this.getConcessionarias();
  }

  // Necessario em todos os form abertos em ActionFormDialog
  action() {
    if (this.props.handleFormState) {
      this.props.handleFormState(this.state);
    }
  }

  handleChange(e, field, int) {
    if (int == "int") {
      // Remove tudo o que não é número
      const newValue = e.target.value.replace(/[^\d]/g, '');
      this.setState({ [field]: newValue });
    } else {
      let { name, value } = e.target;
      if (name == 'consultor_id' && value == '') value = null;
      if (name == 'slave_id' && (value > 200 || value < 2)) return;
      if (name == 'lat' && (value > 90 || value < -90)) return; //limita latitude
      if (name == 'long' && (value > 180 || value < -180)) return; //limita longitude
      this.setState({ [name]: value });
    }
  }

  isShowShrink(value) {
    return value !== undefined && value !== "" && value !== null;
  }

  renderGatewayElements(gateway) {
    const { classes } = this.props;
    switch (gateway) {
      case "ABS":
        return (
          <>
            <Grid container className={classes.container} spacing={2} style={{ marginRight: 3, marginLeft: 2 }}>
              <Grid item xs={6} className={classes.container}>
                <FormControl fullWidth>
                  <InputLabel shrink={true}>
                    <span style={{ fontSize: 22 }}>Host</span>
                  </InputLabel>
                  <NativeSelect
                    fullWidth
                    id="host_equip"
                    label="Selecione o host"
                    value={this.state.host_equip}
                    name="host_equip"
                    onChange={this.handleChange}
                  >
                    {hostsABNT.map((host) => (
                      <option
                        key={host}
                        value={host}
                      >{`${host}`}</option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item xs={6} className={classes.container}>
                <TextField
                  id="host_port"
                  label="Porta host"
                  name="host_port"
                  fullWidth
                  type="text"
                  value={this.state.host_port}
                  onChange={(e) => this.handleChange(e, "host_port", "int")}
                  required
                ></TextField>
              </Grid>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span style={{ fontSize: 22 }}>Metodo de conexão</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  id="Selecione o metodo de conexão"
                  label="Selecione o metodo de conexão"
                  value={this.state.connection}
                  name="connection"
                  onChange={this.handleChange}
                  required
                >
                  <option value={''}
                    style={{ fontSize: "2px", visibility: "hidden" }}
                  ></option>
                  <option value={"ETHERNET"}>ETHERNET</option>
                  <option value={"CELULAR"}>CELULAR</option>
                </NativeSelect>
              </FormControl>
            </Grid>
          </>
        )
      case "Mardey-sat":
        return (
          <Grid item xs={4} className={classes.container}>
            <TextField
              id="codigo_esn"
              label="Código ESN (satélite)"
              name="codigo_esn"
              fullWidth
              required
              type="text"
              value={this.state.codigo_esn}
              onChange={this.handleChange}
            ></TextField>
          </Grid>
        )
    }
  };

  renderEquipmentElements(equipment) {
    const { classes } = this.props;
    switch (equipment) {
      case "ABB":
        return (
          <>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="tensao_corrente"
                label="Primário TC"
                name="tensao_corrente"
                fullWidth
                required
                type="number"
                value={this.state.tensao_corrente}
                onChange={(e) => this.handleChange(e, "tensao_corrente", "int")}
              ></TextField>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="transf_potencia1"
                label="Primário TP"
                name="transf_potencia1"
                fullWidth
                required
                type="number"
                value={this.state.transf_potencia1}
                onChange={(e) => this.handleChange(e, "transf_potencia1", "int")}
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="transf_potencia2"
                label="Secundário TP"
                name="transf_potencia2"
                fullWidth
                required
                type="number"
                value={this.state.transf_potencia2}
                onChange={(e) => this.handleChange(e, "transf_potencia2", "int")}
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="tf_soma_cargas"
                label="Soma das Cargas (A)"
                name="soma_cargas"
                fullWidth
                required
                type="number"
                value={this.state.soma_cargas}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="slave_id"
                label="Slave ID"
                name="slave_id"
                fullWidth
                type="number"
                value={this.state.slave_id}
                onChange={this.handleChange}
              ></TextField>
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={4} className={classes.container}>
                <FormControlLabel
                  label={`Horário de ponta?`}
                  style={{ marginTop: 30 }}
                  control={
                    <Checkbox
                      style={{ marginLeft: 0, color: colors.GREY }}
                      color="secondary"
                      value={"horario_ponta"}
                      onChange={(e) => {
                        this.setState({
                          horario_ponta: !this.state.horario_ponta,
                        });
                      }}
                      checked={this.state.horario_ponta}
                      edge="start"
                    />
                  }
                />
              </Grid>

              {this.state.horario_ponta && (
                <>
                  <Grid item xs={12} md={4} className={classes.container}>
                    <NumberFormat
                      id="tf_horario_ponta_inicio"
                      label="Início horário de ponta"
                      name="horario_ponta_inicio"
                      fullWidth
                      customInput={TextField}
                      format={`##:##:##`}
                      type="text"
                      value={this.state.horario_ponta_inicio}
                      onChange={this.handleChange}
                    ></NumberFormat>
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.container}>
                    <NumberFormat
                      id="tf_horario_ponta_duracao"
                      label="Duração Horário de Ponta"
                      name="horario_ponta_duracao"
                      fullWidth
                      customInput={TextField}
                      format={`##:##:##`}
                      type="text"
                      value={this.state.horario_ponta_duracao}
                      onChange={this.handleChange}
                    ></NumberFormat>
                  </Grid>
                </>
              )}
            </Grid>

            <Grid container item xs={12} spacing={1}>
              <Grid item xs={4} className={classes.container}>
                <FormControlLabel
                  label={`Horário reservado?`}
                  style={{ marginTop: 30 }}
                  control={
                    <Checkbox
                      style={{ marginLeft: 0, color: colors.GREY }}
                      color="secondary"
                      value={"horario_reservado"}
                      onChange={(e) => {
                        this.setState({
                          horario_reservado: !this.state.horario_reservado,
                        });
                      }}
                      checked={this.state.horario_reservado}
                      edge="start"
                    />
                  }
                />
              </Grid>
              {this.state.horario_reservado && (
                <>
                  <Grid item xs={4} className={classes.container}>
                    <NumberFormat
                      id="tf_horario_reservado_inicio"
                      label="Início horário reservado"
                      name="horario_reservado_inicio"
                      fullWidth
                      customInput={TextField}
                      format={`##:##:##`}
                      type="text"
                      value={this.state.horario_reservado_inicio}
                      onChange={this.handleChange}
                    ></NumberFormat>
                  </Grid>
                  <Grid item xs={4} className={classes.container}>
                    <NumberFormat
                      id="tf_horario_reservado_fim"
                      label="Fim horário reservado"
                      name="horario_reservado_fim"
                      fullWidth
                      customInput={TextField}
                      format={`##:##:##`}
                      type="text"
                      value={this.state.horario_reservado_fim}
                      onChange={this.handleChange}
                    ></NumberFormat>
                  </Grid>
                </>
              )}
            </Grid>
          </>
        )
      case "ABNT":
        return (
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="relacao_tp"
                label="RTP"
                name="relacao_tp"
                fullWidth
                required
                type="text"
                value={this.state.relacao_tp}
                onChange={(e) => this.handleChange(e, "relacao_tp", "int")}
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="relacao_tc"
                label="RTC"
                name="relacao_tc"
                fullWidth
                required
                type="text"
                value={this.state.relacao_tc}
                onChange={(e) => this.handleChange(e, "relacao_tc", "int")}
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <TextField
                {...this.state.soma_cargas = null}
                id="constante_eletronica"
                label="Constante eletronica (Ke)"
                name="constante_eletronica"
                fullWidth
                type="number"
                value={this.state.constante_eletronica}
                onChange={this.handleChange}
                required={true}
              ></TextField>
            </Grid>
          </Grid>
        )
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.formControl}
        >
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="tf_fazenda"
                label="Nome da Fazenda"
                name="fazenda"
                fullWidth
                required
                type="text"
                value={this.state.fazenda}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="regiao"
                label="Região"
                name="regiao"
                fullWidth
                type="text"
                value={this.state.regiao}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="tf_lat"
                label="Latitude"
                name="lat"
                fullWidth
                type="number"
                value={this.state.lat}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="tf_long"
                label="Longitude"
                name="long"
                fullWidth
                type="number"
                value={this.state.long}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span style={{ fontSize: 22 }}>Selecione o cliente</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  id="Selecione o cliente"
                  label="Selecione o cliente"
                  value={this.state.usuario_id}
                  required
                  name="usuario_id"
                  onChange={this.handleChange}
                >
                  {[<option key="sUser" value=""></option>].concat(
                    this.state.usuarios.map((usuario) => {
                      if (usuario.permissao == 1 && usuario.produto != 1) {
                        return (
                          <option key={usuario.id} value={usuario.id}>
                            {usuario.id} - {usuario.nome}
                          </option>
                        );
                      };
                    })
                  )}
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span style={{ fontSize: 22 }}>Selecione o consultor</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  id="Selecione o consultor"
                  label="Selecione o consultor"
                  value={this.state.consultor_id}
                  name="consultor_id"
                  onChange={this.handleChange}
                >
                  {[<option key="sUser" value={null}></option>].concat(
                    this.state.usuarios.map((usuario) => {
                      if (usuario.permissao == 2) {
                        return (
                          <option key={usuario.id} value={usuario.id}>
                            {usuario.id} - {usuario.nome}
                          </option>
                        );
                      };
                    })
                  )}
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="tf_intermediante"
                label="Intermediante"
                name="intermediante"
                fullWidth
                type="text"
                value={this.state.intermediante}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="tf_nome_bombeamento"
                label="Local de medição" //Este campo deve ser apresentado como Local de medição
                name="nome_bombeamento"
                fullWidth
                type="text"
                value={this.state.nome_bombeamento}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="id_equipamento"
                label="Id do Equipamento"
                name="id_equipamento"
                fullWidth
                required
                type="text"
                value={this.state.id_equipamento}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span style={{ fontSize: 22 }}>Tipo de medidor</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  id="Selecione o medidor"
                  label="Selecione o medidor"
                  value={this.state.equipamento}
                  name="equipamento"
                  onChange={this.handleChange}
                >
                  <option value={''}
                    style={{ fontSize: "2px", visibility: "hidden" }}
                  ></option>
                  <option value={"ABNT"}>ABNT</option>
                  <option value={"ABB"}>ABB</option>
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span style={{ fontSize: 22 }}>Selecione o gateway</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  id="Selecione o gateway"
                  label="Selecione o gateway"
                  value={this.state.gateway}
                  name="gateway"
                  onChange={this.handleChange}
                >
                  <option value={''}
                    style={{ fontSize: "2px", visibility: "hidden" }}
                  ></option>
                  <option value={"ABS"}>ABS</option>
                  <option value={"Mardey-sat"}>Mardey-sat</option>
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>

          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="potencia_transf"
                label="Potência do Transformador"
                name="potencia_transf"
                fullWidth
                type="number"
                value={this.state.potencia_transf}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="potencia_instalada"
                label="Potência instalada"
                name="potencia_instalada"
                fullWidth
                type="number"
                value={this.state.potencia_instalada}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>

          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="demanda_contratada"
                label="Demanda contratada (kW)"
                name="demanda_contratada"
                fullWidth
                type="number"
                value={this.state.demanda_contratada}
                onChange={this.handleChange}
                required={true}
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="tf_soma_kwa"
                label="Somatório Kva"
                name="soma_kwa"
                fullWidth
                type="number"
                value={this.state.soma_kwa}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid
              item
              xs={4}
              style={{ marginTop: "15px" }}
              className={classes.container}
            >
              <CustomDatePicker
                id="date_account"
                fullWidth
                label="Aniversario da conta"
                iconcolor={colors.GREEN}
                variant="inline"
                format="DD/MM/YYYY"
                name="date_account"
                value={this.state.date_account}
                onChange={this.handleChange}
                KeyboardButtonProps={{
                  "aria-label": "Mudar data",
                }}
              />
            </Grid>

            <Grid item xs={8} className={classes.container}>
            <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span style={{ fontSize: 22 }}>Selecione a concessionária</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  id="Selecione a concessionária"
                  label="Selecione a concessionária"
                  value={this.state.concessionaria_id}
                  required
                  name="concessionaria_id"
                  onChange={this.handleChange}
                >
                  {[<option key="concessionaria_id" value=""></option>].concat(
                    this.state.concessionarias.map((concessionaria) => {
                        return (
                          <option key={concessionaria.id} value={concessionaria.id}>
                            {concessionaria.id} - {concessionaria.nome} - {concessionaria.regiao}
                          </option>
                        );
                    })
                  )}
                </NativeSelect>
              </FormControl>
            </Grid>

            <Grid container className={classes.container} spacing={2}>
              <Grid item xs={4} className={classes.container}>
                <FormControlLabel
                  label={`Energia injedata?`}
                  style={{ marginTop: 30 }}
                  control={
                    <Checkbox
                      style={{ marginLeft: 0, color: colors.GREY }}
                      color="secondary"
                      value={"energia_injetada"}
                      onChange={(e) => {
                        this.setState({
                          energia_injetada: !this.state.energia_injetada,
                        });
                        if (!e.target.checked) {
                          this.setState({
                            demanda_geracao: null,
                            potencia_usina: null,
                          });
                        }
                      }}
                      checked={this.state.energia_injetada}
                      edge="start"
                    />
                  }
                />
              </Grid>
              {this.state.energia_injetada &&
                <>
                  <Grid item xs={4} className={classes.container}>
                    <TextField
                      id="demanda_geracao"
                      label="Demanda de geração (kW)"
                      name="demanda_geracao"
                      fullWidth
                      type="number"
                      value={this.state.demanda_geracao}
                      onChange={this.handleChange}
                      required={this.state.energia_injetada}
                    ></TextField>
                  </Grid>
                  <Grid item xs={4} className={classes.container}>
                    <TextField
                      id="potencia_usina"
                      label="Potência da usina (kW)"
                      name="potencia_usina"
                      fullWidth
                      type="number"
                      value={this.state.potencia_usina}
                      onChange={this.handleChange}
                      required={this.state.energia_injetada}
                    ></TextField>
                  </Grid>
                </>
              }
            </Grid>

            {this.renderEquipmentElements(this.state.equipamento)}
            {this.renderGatewayElements(this.state.gateway)}
          </Grid>
        </FormControl>
      </form>
    );
  };
}

export default withStyles(styles)(FormProdutor);
