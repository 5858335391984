export const requiredPowerFields = [
  {name: "Nome da Fazenda", value: "fazenda"},
  {name: "Cliente", value: "usuario_id"},
  {name: "Equipamento Id", value: "id_equipamento"},
  {name: "Demanda contratada (kW)", value: "demanda_contratada"},
  {name: "Concessionária", value: "concessionaria_id"}
]

export const requiredAbntFields = [
  {name: "RTP", value: "relacao_tp"},
  {name: "RTC", value: "relacao_tc"},
  {name: "Constante Eletronica", value: "constante_eletronica"},
]

export const requiredAbbFields = [
  {name: "Primário TC", value: "tensao_corrente"},
  {name: "Primário TP", value: "transf_potencia1"},
  {name: "Secundário TP", value: "transf_potencia2"},
  {name: "Soma das Cargas", value: "soma_cargas"},
]

export const requiredAbsFields = [
  {name: "Porta Host", value: "host_port"},
]

export const requiredMardeyFields = [
  {name: "Código ESN", value: "codigo_esn"},
]

export const requiredWabsFields =[
  {name: "Nome da Fazenda", value: "fazenda"},
  {name: "Cliente", value: "usuario_id"},
  {name: "Equipamento Id", value: "id_equipamento"},
  {name: "Potência da bomba", value: "potencia_instalada"},
  {name: "Vazão Nominal", value: "vazao"},
  {name: "Outorga", value: "outorga"},
]

export const requiredConcessionariaFields = [
  {name: "nome da concessionária", value: "nome"},
  {name: "região", value: "regiao"},
  {name: "Tarifa HP", value: "tarifa_hp"},
  {name: "Tarifa HFP", value: "tarifa_hfp"},
  {name: "Tarifa HR", value: "tarifa_hr"},
  {name: "Tarifa Demanda Ativa", value: "tarifa_demanda_ativa"},
  {name: "Tarifa Demanda Reativa", value: "tarifa_demanda_reativa"},
  {name: "Tarifa Ultrapassagem", value: "tarifa_ultrapassagem"},
  {name: "Tarifa Reativa", value: "tarifa_hfp_reativo"},
]