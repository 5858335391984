import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  FormControl,
  TextField,
  NativeSelect,
  Grid,
  Checkbox,
  FormControlLabel,
  InputLabel,
} from "@material-ui/core";
import NumberFormat from "react-number-format";
import { userService } from "../services/user_service";
import { validateShowResponseToast } from "../helpers/toast_alerts";
import CustomDatePicker from "../default/custom/CustomDatePicker";
import { colors } from "../constants/dc_constants";
import { hostsABNT } from "../constants/headRows"
import { GestureRounded } from "@material-ui/icons";
import { toast } from "react-toastify";
const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  form: {
    minHeigth: 600,
    maxWidth: 775,
    minWidth: 775,
  },
  formControl: {
    minWidth: "220px",
  },
  container: {
    marginTop: "15px",
  },
});

class FormProdutor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: 0,
      usuario_id: "",
      id_equipamento: "",
      fazenda: "",
      lat: "",
      long: "",
      regiao: "",
      produto: "water",
      potencia_instalada: "", // Será utilizado para definir a potencia da bomba de agua.
      codigo_esn: "", // Será utilizado para definir o macid
      connection: "",
      tipo_hidrometro: "",
      gateway:"",
      usuarios: [],

      //ABS
      host_equip: hostsABNT[0],
      host_port: null,
      entrada_gpio: null,
    };

    this.handleChange = this.handleChange.bind(this);
  }

  getUsuarios = () => {
    userService.getUsuarios({}, (response) => {
      if (validateShowResponseToast(response, "", false)) {
        this.setState({
          usuarios: response.data,
        });
      }
    });
  };

  componentDidMount() {
    const data = this.props.data;
    if (data) {
      const checkHorarioPonta = data?.horario_ponta_inicio ? true : false
      this.setState({
        ...data,
        horario_ponta: checkHorarioPonta,
        host_equip: data.host_equip ?? hostsABNT[0],
      });
    }
    this.getUsuarios()
  }

  // Necessario em todos os form abertos em ActionFormDialog
  action() {
    if (this.props.handleFormState) {
      this.props.handleFormState(this.state);
    }
  }

  handleChange(e, field, int) {
    if (int == "int") {
      // Remove tudo o que não é número
      const newValue = e.target.value.replace(/[^\d]/g, '');
      this.setState({ [field]: newValue });
    } else {
      const { name, value } = e.target;
      if (name == 'lat' && (value > 90 || value < -90)) return; //limita latitude
      if (name == 'long' && (value > 180 || value < -180)) return; //limita longitude
      this.setState({ [name]: value });
    }
  }

  isShowShrink(value) {
    return value !== undefined && value !== "" && value !== null;
  }

  renderGatewayElements(gateway) {
    const { classes } = this.props;
    switch (gateway) {
      case "ABS":
        return (
          <>
            <Grid container className={classes.container} spacing={2} style={{ marginRight: 3, marginLeft: 2 }}>
              <Grid item xs={4} className={classes.container}>
                <FormControl fullWidth>
                  <InputLabel shrink={true}>
                    <span style={{ fontSize: 22 }}>Host</span>
                  </InputLabel>
                  <NativeSelect
                    fullWidth
                    id="host_equip"
                    label="Selecione o host"
                    value={this.state.host_equip}
                    name="host_equip"
                    onChange={this.handleChange}
                  >
                    {hostsABNT.map((host) => (
                      <option
                        key={host}
                        value={host}
                      >{`${host}`}</option>
                    ))}
                  </NativeSelect>
                </FormControl>
              </Grid>
              <Grid item xs={4} className={classes.container}>
                <TextField
                  id="host_port"
                  label="Porta host"
                  name="host_port"
                  fullWidth
                  type="text"
                  value={this.state.host_port}
                  onChange={(e) => this.handleChange(e, "host_port", "int")}
                  required
                ></TextField>
              </Grid>
              <Grid item xs={4} className={classes.container}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span style={{ fontSize: 22 }}>Entrada GPIO</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  id="Entrada GPIO"
                  label="Entrada GPIO"
                  value={this.state.entrada_gpio}
                  name="entrada_gpio"
                  onChange={this.handleChange}
                >
                  <option value={''}
                    style={{ fontSize: "2px", visibility: "hidden" }}
                  ></option>
                  <option value={"1"}>1</option>
                  <option value={"2"}>2</option>
                  <option value={"3"}>3</option>
                  <option value={"4"}>4</option>
                  <option value={"5"}>5</option>
                  <option value={"6"}>6</option>
                  <option value={"7"}>7</option>
                  <option value={"8"}>8</option>
                </NativeSelect>
              </FormControl>
            </Grid>
            </Grid>
          </>
        )
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <FormControl
          variant="outlined"
          fullWidth
          className={classes.formControl}
        >
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="tf_fazenda"
                label="Nome da Fazenda"
                name="fazenda"
                fullWidth
                type="text"
                value={this.state.fazenda}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="regiao"
                label="Região"
                name="regiao"
                fullWidth
                type="text"
                value={this.state.regiao}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="tf_lat"
                label="Latitude"
                name="lat"
                fullWidth
                type="number"
                value={this.state.lat}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="tf_long"
                label="Longitude"
                name="long"
                fullWidth
                type="number"
                value={this.state.long}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span style={{ fontSize: 22 }}>Selecione o cliente</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  id="Selecione o cliente"
                  label="Selecione o cliente"
                  value={this.state.usuario_id}
                  name="usuario_id"
                  onChange={this.handleChange}
                >
                  {[<option key="sUser" value=""></option>].concat(
                    this.state.usuarios.map((usuario) => {
                      if (usuario.permissao == 1 && usuario.produto != 0) {
                        return (
                          <option key={usuario.id} value={usuario.id}>
                            {usuario.id} - {usuario.nome}
                          </option>
                        );
                      };
                    })
                  )}
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span style={{ fontSize: 22 }}>Selecione o consultor</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  id="Selecione o consultor"
                  label="Selecione o consultor"
                  value={this.state.consultor_id}
                  name="consultor_id"
                  onChange={this.handleChange}
                >
                  {[<option key="sUser" value=""></option>].concat(
                    this.state.usuarios.map((usuario) => {
                      if (usuario.permissao == 2) {
                        return (
                          <option key={usuario.id} value={usuario.id}>
                            {usuario.id} - {usuario.nome}
                          </option>
                        );
                      };
                    })
                  )}
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="tf_intermediante"
                label="Intermediante"
                name="intermediante"
                fullWidth
                type="text"
                value={this.state.intermediante}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <TextField
                id="tf_nome_bombeamento"
                label="Local de medição" //Este campo deve ser apresentado como Local de medição
                name="nome_bombeamento"
                fullWidth
                type="text"
                value={this.state.nome_bombeamento}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="id_equipamento"
                label="Id do Equipamento"
                name="id_equipamento"
                fullWidth
                type="text"
                value={this.state.id_equipamento}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="codigo_esn"
                label="MacID"
                name="codigo_esn"
                fullWidth
                type="text"
                value={this.state.codigo_esn}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span style={{ fontSize: 22 }}>Metodo de conexão</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  id="Selecione o metodo de conexão"
                  label="Selecione o metodo de conexão"
                  value={this.state.connection}
                  name="connection"
                  onChange={this.handleChange}
                  required
                >
                  <option value={''}
                    style={{ fontSize: "2px", visibility: "hidden" }}
                  ></option>
                  <option value={"ETHERNET"}>ETHERNET</option>
                  <option value={"CELULAR"}>CELULAR</option>
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="potencia_instalada"
                label="Potência da bomba (kW)"
                name="potencia_instalada"
                fullWidth
                type="number"
                value={this.state.potencia_instalada}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="vazao"
                label="Vazão m³/h"
                name="vazao"
                fullWidth
                type="number"
                value={this.state.vazao}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
            <Grid item xs={4} className={classes.container}>
              <TextField
                id="outorga"
                label="Outorga"
                name="outorga"
                fullWidth
                type="number"
                value={this.state.outorga}
                onChange={this.handleChange}
              ></TextField>
            </Grid>
          </Grid>
          <Grid container className={classes.container} spacing={2}>
            <Grid item xs={6} className={classes.container}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span style={{ fontSize: 22 }}>Tipo de Hidrometro</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  id="Tipo de hidrometro"
                  label="Selecione o tipo de hidrometro"
                  value={this.state.tipo_hidrometro}
                  name="tipo_hidrometro"
                  onChange={this.handleChange}
                >
                  <option value={''}
                    style={{ fontSize: "2px", visibility: "hidden" }}
                  ></option>
                  <option value={"horimetro"}>Horimetro</option>
                  <option value={"pulsado"}>Pulsado</option>
                </NativeSelect>
              </FormControl>
            </Grid>
            <Grid item xs={6} className={classes.container}>
              <FormControl fullWidth>
                <InputLabel shrink={true}>
                  <span style={{ fontSize: 22 }}>Gateway</span>
                </InputLabel>
                <NativeSelect
                  fullWidth
                  id="gateway"
                  label="Selecione o gateway"
                  value={this.state.gateway}
                  name="gateway"
                  onChange={this.handleChange}
                >
                  <option value={''}
                    style={{ fontSize: "2px", visibility: "hidden" }}
                  ></option>
                  <option value={"fantus"}>Fantus</option>
                  <option value={"onboard"}>Onboard</option>
                  <option value={"ABS"}>ABS</option>
                </NativeSelect>
              </FormControl>
            </Grid>
          </Grid>
          {this.renderGatewayElements(this.state.gateway)}
        </FormControl>
      </form>
    );
  }
}

export default withStyles(styles)(FormProdutor);
