import { getAxiosInstance } from '../services/service';
import { makeQuery } from './service_util';

export const coletaService = {
	getColetas,
	getColetasPagination,
	getUltimasColetas,
	getColetasAgua,
	getUltimasColetasAgua,
	getDemandaMensal,
};

async function getColetasAgua(data, handleResponse, handleAction) {
	let response = undefined;
	let errorResponse = undefined;

	response = await getAxiosInstance(`/coletasAgua?gestorId=${data.gestor_id}&dataInicial=${data.data_inicial}&dataFinal=${data.data_final}&attr=${data.attr || ""}`)
	.get().catch(error => {
		errorResponse = error.response || undefined;
	});

	if(handleResponse) handleResponse(response || errorResponse);
	if(handleAction) handleAction();
}

async function getColetas(data, handleResponse, handleAction) {
	let response = undefined;
	let errorResponse = undefined;

	response = await getAxiosInstance(`/coletas?gestorId=${data.gestor_id}&dataInicial=${data.data_inicial}&dataFinal=${data.data_final}&attr=${data.attr || ""}`)
	.get().catch(error => {
		errorResponse = error.response || undefined;
	});

	if(handleResponse) handleResponse(response || errorResponse);
	if(handleAction) handleAction();
}

async function getColetasPagination(data, handleResponse, handleAction) {
	let response = undefined;
	let errorResponse = undefined;
	let allColetas = [];
	let hasMore = true;
	let limit = data.limit;
	let offset = data.offset;

	try {
		while (hasMore) {
			response = await getAxiosInstance(`/coletas/pagination?limit=${limit}&offset=${offset}&gestorId=${data.gestor_id}&dataInicial=${data.data_inicial}&dataFinal=${data.data_final}&attr=${data.attr || ""}`)
			.get().catch(error => {
				errorResponse = error.response || undefined
			});

			allColetas = allColetas.concat(response.data);

			if (response.data.length < limit) {
        hasMore = false;
      } else {
        offset += limit;
      }
		}
	} catch (error) {
		errorResponse = `Error fetching data: ${error}`;
		hasMore = false;
	}

	response.data = allColetas;

	if(handleResponse) handleResponse(response || errorResponse);
	if(handleAction) handleAction();
}

async function getDemandaMensal(data, handleResponse, handleAction){
	let response = undefined;
	let errorResponse = undefined;

	response = await getAxiosInstance(`/coletas/anual?gestorId=${data.gestor_id}&year=${data.year}`)
	.get().catch(error =>{
		errorResponse = error.response || undefined;
	});

	if (handleResponse) handleResponse(response || errorResponse);
	if (handleAction) handleAction();
}

async function getUltimasColetas(data, handleResponse, handleAction) {
	let response = undefined;
	let errorResponse = undefined;

	const query = makeQuery(data);

	response = await getAxiosInstance(`/coletas/ultimas${query}`)
	.get().catch(error => {
		errorResponse = error.response || undefined;
	});

	if(handleResponse) handleResponse(response || errorResponse);
	if(handleAction) handleAction();
}

async function getUltimasColetasAgua(data, handleResponse, handleAction) {
	let response = undefined;
	let errorResponse = undefined;

	const query = makeQuery(data);

	response = await getAxiosInstance(`/coletasAgua/ultimas${query}`)
	.get().catch(error => {
		errorResponse = error.response || undefined;
	});

	if(handleResponse) handleResponse(response || errorResponse);
	if(handleAction) handleAction();
}
