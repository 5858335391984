import jsPDF from "./jspdf_override";
import { makeColsAndRows } from "./template_table";
import { IMG_LOGO } from "../../constants/base64Images";
import { makeTable } from "./jspdf_override";
import { formatMoney } from "../table/formatter";
import html2canvas from "html2canvas";

const CONFIG_PARAMS = {
  initialLine: 0,

  smallVerticalSpace: 2,
  mediumVerticalSpace: 7,
  bigVerticalSpace: 10,

  lineVerticalSapace: 2,
  lineHorizontalSpace: 2,

  smallHorizontalSpace: 2,
  mediumHorizontalSpace: 5,
  bigHorizontalSpace: 10,

  tableSize: 20,
  smallFont: 8,
  mediumFont: 12,
  bigFont: 24,

  fontColorPrimary: [0, 0, 0],
  fontColorSecondary: [0, 0, 255],
  fontColorTertiary: [255, 0, 0],
};

const defaultParams = {
  title: "",
  orientation: "p",
  data_hora: "",
  //equipamento: "",
  local: "",
  regiao: "",
  data_inicio: "",
  data_final: "",
};

export function header(doc, params) {
  doc.setFont("times");
  doc.setFontSize(12);
  var img = IMG_LOGO;
  let verticalLine = CONFIG_PARAMS.mediumVerticalSpace;
  let horizontalLine = CONFIG_PARAMS.mediumHorizontalSpace;
  doc.addImage(img, "PNG", 5, 5, 40, 20);
  doc.writeText(params.title, { align: "center" }, 0, verticalLine);


  verticalLine += CONFIG_PARAMS.mediumVerticalSpace;
  horizontalLine += 45;

  doc.writeText(
    `Data e hora: ${params.data_hora}`,
    {},
    horizontalLine,
    verticalLine
  );
  /*
  verticalLine += CONFIG_PARAMS.mediumVerticalSpace;
  doc.writeText(
    `Equipamento: ${params.equipamento}`,
    {},
    horizontalLine,
    verticalLine
  );
  */
  verticalLine += CONFIG_PARAMS.mediumVerticalSpace;
  doc.writeText(
    `Localidade do Equipamento: ${params.regiao} - ${params.local}`,
    {},
    horizontalLine,
    verticalLine
  );
  verticalLine += CONFIG_PARAMS.mediumVerticalSpace;
  doc.writeText(
    `Período do Relatório: De ${params.data_inicio} até ${params.data_final}`,
    {},
    horizontalLine,
    verticalLine
  );

  // Somente para o relatorio de economia
  if (params.totalEconomia) {
    verticalLine += CONFIG_PARAMS.mediumVerticalSpace;
    doc.writeText(
      `Economia Total: ${formatMoney(params.totalEconomia)}`,
      {},
      horizontalLine,
      verticalLine
    );
  }

  return [horizontalLine, verticalLine];
}

export function template_relatorio(
  data = [],
  filterRows = [],
  params = defaultParams
) {
  let doc = new jsPDF(params.orientation || "p", "mm", "a4");
  const ret = header(doc, params);
  //let horizontalLine = ret[0]
  let verticalLine = ret[1];
  const colsAndRows = makeColsAndRows(data, filterRows);
  verticalLine += CONFIG_PARAMS.mediumVerticalSpace;
  makeTable(doc, [colsAndRows.cols], colsAndRows.rows, verticalLine, 7);
  return doc;
}

export function template_fechamento(values = [], params = defaultParams) {
  let doc = new jsPDF(params.orientation || "p", "mm", "a4");
  doc.setFont("times");
  const ret = header(doc, params);
  let verticalLine = ret[1];

  let horizontalLine = CONFIG_PARAMS.mediumHorizontalSpace;

  //doc.setLineWidth(1.5);
  doc.line(0, 40, 1000, 40);

  verticalLine += CONFIG_PARAMS.mediumVerticalSpace + 12;
  doc.setFontSize(18);
  doc.setFontType("bold");
  doc.writeText(
    `Fechamento com Dados Históricos do Medidor`,
    { align: "center" },
    horizontalLine,
    verticalLine
  );
  doc.setFontType("normal");
  doc.setFontSize(16);
  verticalLine += CONFIG_PARAMS.bigVerticalSpace + 8;
  doc.writeText(
    `Consumo ativo acumulado no período: ${values.kw_total} kwh`,
    {},
    horizontalLine,
    verticalLine
  );
  verticalLine += CONFIG_PARAMS.bigVerticalSpace + 4;
  doc.writeText(
    `Consumo reativo acumulado no período: ${values.kvar_total} kvarh`,
    {},
    horizontalLine,
    verticalLine
  );

  doc.setFontSize(16);
  verticalLine += CONFIG_PARAMS.bigVerticalSpace + 4;
  doc.writeText(
    `Consumo ativo por posto horário : `,
    {},
    horizontalLine,
    verticalLine
  );
  verticalLine += CONFIG_PARAMS.bigVerticalSpace;
  doc.setFontSize(14);
  doc.writeText(
    `Kwh HP: ${values.kw_ponta} kwh`,
    {},
    horizontalLine,
    verticalLine
  );
  verticalLine += CONFIG_PARAMS.bigVerticalSpace;
  doc.writeText(
    `Kwh HFP: ${values.kw_fora_ponta} kwh`,
    {},
    horizontalLine,
    verticalLine
  );
  verticalLine += CONFIG_PARAMS.bigVerticalSpace;
  doc.writeText(
    `Kwh HR: ${values.kw_reservado} kwh`,
    {},
    horizontalLine,
    verticalLine
  );

  doc.setFontSize(16);
  verticalLine += CONFIG_PARAMS.bigVerticalSpace + 4;
  doc.writeText(
    `Consumo reativo por posto horário: : `,
    {},
    horizontalLine,
    verticalLine
  );
  verticalLine += CONFIG_PARAMS.bigVerticalSpace;
  doc.setFontSize(14);
  doc.writeText(
    `Kvarh HP: ${values.kvar_ponta}`,
    {},
    horizontalLine,
    verticalLine
  );
  verticalLine += CONFIG_PARAMS.bigVerticalSpace;
  doc.writeText(
    `Kvarh HFP: ${values.kvar_fora_ponta}`,
    {},
    horizontalLine,
    verticalLine
  );
  verticalLine += CONFIG_PARAMS.bigVerticalSpace;
  doc.writeText(
    `Kvarh HR: ${values.kvar_reservado}`,
    {},
    horizontalLine,
    verticalLine
  );

  doc.setFontSize(16);
  verticalLine += CONFIG_PARAMS.bigVerticalSpace + 4;
  doc.writeText(
    `Demanda registrada Ativa por posto horário: : `,
    {},
    horizontalLine,
    verticalLine
  );
  verticalLine += CONFIG_PARAMS.bigVerticalSpace;
  doc.setFontSize(14);
  doc.writeText(
    `Kw HP: ${values.kw_hp_max} kw`,
    {},
    horizontalLine,
    verticalLine
  );
  verticalLine += CONFIG_PARAMS.bigVerticalSpace;
  doc.writeText(
    `Kw HFP: ${values.kw_hfp_max} kw`,
    {},
    horizontalLine,
    verticalLine
  );
  verticalLine += CONFIG_PARAMS.bigVerticalSpace;
  doc.writeText(
    `Kw HR: ${values.kw_hr_max} kw`,
    {},
    horizontalLine,
    verticalLine
  );

  return doc;
}

export async function htmlToPng(element) {
  const imgElement = await html2canvas(element);
  return imgElement.toDataURL("image/png");
}

export function template_completo(
  params = {
    headerParams: {...defaultParams, year},

    dadosMassaRelatorio: { show: false, headers: [], data: [], title: "" },
    dadosAneel: { show: false, headers: [], data: [], title: "" },
    dadosEconomia: { show: false, headers: [], data: [], title: "" },
    dadosEquipamento: { show: false, headers: [], data: [], title: "" },
    dadosQueda: { show: false, headers: [], data: [], total: 0, maior: 0, title: "" },
    dadosDetalhado: { show: false, headers: [], data: [], title: "" },

    divRelatorioFaturaPng: null,
    divRelatorioFechamentoPng: null,
    divConsumoAguaPng: null,

    correnteChartPng: null,
    potenciaChartPng: null,
    tensaoChartPng: null,
    consumoChartPng: null,
    demandaAnualChartPng: null,
    somenteFechamento: false
  }
) {
  let doc = new jsPDF(params?.headerParams?.orientation || "p", "mm", "a4");
  doc.setFont("times");

  let headerLine = 10;
  let hasReportBefore = false;
  let consumoAgua = false;

  if (!params.divRelatorioFaturaPng && !params.divRelatorioFechamentoPng && !params.divConsumoAguaPng) {
    header(doc, params.headerParams);
    doc.line(0, 40, 1000, 40);

    headerLine = 50;
  }
  // Relatorio de Fatura
  if (params.divRelatorioFaturaPng) {
    doc.addImage(params.divRelatorioFaturaPng, "PNG", 10, 0, 190, 270, "");

    hasReportBefore = true;
  }

  // Relatorio de Fechamento
  if (params.divRelatorioFechamentoPng) {
    if (hasReportBefore) {
      doc.addPage();
    }

    hasReportBefore = true;

    doc.addImage(params.divRelatorioFechamentoPng, "PNG", 10, params.somenteFechamento ? -10 : 0, 190, 110, "");
  }

  // Relatorio Gerencial, gráficos (Corrente, Potencia, Tensão)
  var graphicsHeight = headerLine;

  //relatorio Consumo Agua
  if (params.divConsumoAguaPng){
    consumoAgua = true;
    doc.addImage(params.divConsumoAguaPng, "PNG", 10,10,190,70,"");
    graphicsHeight+= 85;
  }

  if (
    params.tensaoChartPng
  ) {

    if (hasReportBefore) {
      doc.addPage();
    }

    hasReportBefore = true;

    doc.writeText("Tensão (V)", {}, 12, graphicsHeight - 5);
    doc.addImage(params.tensaoChartPng, "PNG", 10, graphicsHeight, 190, 70, "", "FAST");
    graphicsHeight += 85
  }

  if (
    params.correnteChartPng
  ) {
    doc.writeText("Corrente (A)", {}, 12, graphicsHeight - 5);
    doc.addImage(params.correnteChartPng, "PNG", 10, graphicsHeight, 190, 70, "", "FAST");
    graphicsHeight += 85
  }

  if (
    params.consumoChartPng
  ) {

    if (hasReportBefore && !params.tensaoChartPng) {
      doc.addPage();
    }

    hasReportBefore = true;

    if(consumoAgua){ 
      doc.writeText("Consumo", {}, 12, graphicsHeight - 5);
      doc.addImage(params.consumoChartPng, "PNG", 10, graphicsHeight, 190, 70, "", "FAST");
      graphicsHeight+= 80;    
    }
    else{
      doc.writeText("Consumo", {}, 12, graphicsHeight - 5);
      doc.addImage(params.consumoChartPng, "PNG", 10, graphicsHeight, 190, 70, "", "FAST");
      graphicsHeight += 85
    }
  }

  if (
    params.potenciaChartPng
  ) {
    doc.writeText("Demanda", {}, 12, graphicsHeight);
    doc.addImage(params.potenciaChartPng, "PNG", 10, graphicsHeight + 10, 190, 70, "", "FAST");
    headerLine = 10;
  }

  // Relatorio demanda anual
  if (
    params.demandaAnualChartPng
  ) {

    if (hasReportBefore) doc.addPage();

    hasReportBefore = true;

    doc.writeText(`Demanda ${params.headerParams.year}`, {}, 12, graphicsHeight);
    doc.addImage(params.demandaAnualChartPng, "PNG", 10, graphicsHeight + 10, 190, 70, "", "FAST");
    headerLine = 10;
  }

  // Relatório dados de massa
  if (params?.dadosMassaRelatorio?.show) {
    if (hasReportBefore) {
      doc.addPage();
    }

    hasReportBefore = true;

    const { data, headers, title } = params.dadosMassaRelatorio;
    let initVerticalLine = headerLine;
    headerLine = 10;
    doc.writeText(title, { align: "left" }, 0, initVerticalLine);
    initVerticalLine += 5;
    const colsAndRows = makeColsAndRows(data, headers);
    makeTable(doc, [colsAndRows.cols], colsAndRows.rows, initVerticalLine);
  }

  //consumo detalhado agua
  if (params?.dadosDetalhado?.show){
    const {data, headers, title} = params.dadosDetalhado;
    let initVerticalLine = graphicsHeight;
    doc.writeText(title, {align: "left"}, 0, initVerticalLine);
    initVerticalLine += 5;
    const colsAndRows = makeColsAndRows(data,headers);
    makeTable(doc, [colsAndRows.cols], colsAndRows.rows, initVerticalLine);
    }

  // Relatório dados de economia
  if (params?.dadosEconomia?.show) {
    if (hasReportBefore) {
      doc.addPage();
    }

    hasReportBefore = true;

    const { data, headers, title } = params.dadosEconomia;
    let initVerticalLine = headerLine;
    headerLine = 10;
    const total = data.reduce(
      (acc, cur) => acc + Number(cur.potencial_economia) || 0,
      0
    );
    doc.writeText(
      `${title}. Total: ${formatMoney(Math.round(total))}`,
      { align: "left" },
      0,
      initVerticalLine
    );
    initVerticalLine += 5;
    const colsAndRows = makeColsAndRows(data, headers);
    makeTable(doc, [colsAndRows.cols], colsAndRows.rows, initVerticalLine);
  }

  // Relatório dados de aneel
  if (params?.dadosAneel?.show) {
    if (hasReportBefore) {
      doc.addPage();
    }

    hasReportBefore = true;

    const { data, headers, title } = params.dadosAneel;
    let initVerticalLine = headerLine;
    headerLine = 10;
    doc.writeText(title, { align: "left" }, 0, initVerticalLine);
    initVerticalLine += 5;
    const colsAndRows = makeColsAndRows(data, headers);
    makeTable(doc, [colsAndRows.cols], colsAndRows.rows, initVerticalLine);
  }

  // Relatório dados do Equipamento
  if (params?.dadosEquipamento?.show) {
    if (hasReportBefore) {
      doc.addPage();
    }

    hasReportBefore = true;

    const { data, headers, title } = params.dadosEquipamento;
    let initVerticalLine = headerLine;
    headerLine = 10;
    doc.writeText(title, { align: "left" }, 0, initVerticalLine);
    initVerticalLine += 5;
    const colsAndRows = makeColsAndRows(data, headers);
    makeTable(doc, [colsAndRows.cols], colsAndRows.rows, initVerticalLine);
  }

  // Relatório queda de energia
  if (params?.dadosQueda?.show) {
    if (hasReportBefore) {
      doc.addPage();
    }
    hasReportBefore = true;

    const { data, headers, total, maior, title } = params.dadosQueda;
    let initVerticalLine = headerLine;
    headerLine = 10;
    doc.writeText(title, { align: "left" }, 0, initVerticalLine);
    initVerticalLine += 10;
    doc.writeText(
      `DIC: ${total}          FIC: ${data.length}          DMIC: ${maior}`,
      { align: "left" },
      0,
      initVerticalLine
    );
    initVerticalLine += 5;
    const colsAndRows = makeColsAndRows(data, headers);
    makeTable(doc, [colsAndRows.cols], colsAndRows.rows, initVerticalLine);
  }

  return doc;
}
