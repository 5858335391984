import { getAxiosInstance } from "../services/service";
import { makeQuery } from "./service_util";

export const gestorService = {
  createGestor,
  getGestores,
  updateGestor,
  deleteGestor,
  getResumoDados,
  getRelatorioAneel,
};

async function createGestor(data, handleResponse, handleAction) {
  let response = undefined;
  let errorResponse = undefined;
  response = await getAxiosInstance("/gestores")
    .post(undefined, data)
    .catch((error) => {
      errorResponse = error.response || undefined;
    });
  if (handleResponse) handleResponse(response || errorResponse);
  if (handleAction) handleAction();
}

async function getGestores(produto, data, handleResponse, handleAction) {
  let response = undefined;
  let errorResponse = undefined;

  response = await getAxiosInstance(`/gestores/${produto}/${data?.statusEquipments ?? 1}`)
    .get()
    .catch((error) => {
      errorResponse = error.response || undefined;
    });
  if (handleResponse) handleResponse(response || errorResponse);
  if (handleAction) handleAction();
}

async function updateGestor(data, handleResponse, handleAction) {
  let response = undefined;
  let errorResponse = undefined;
  const id = data.id || 0;
  response = await getAxiosInstance(`/gestores/${id}`)
    .put(undefined, data)
    .catch((error) => {
      errorResponse = error.response || undefined;
    });
  if (handleResponse) handleResponse(response || errorResponse);
  if (handleAction) handleAction();
}

async function deleteGestor(data, handleResponse, handleAction) {
  let response = undefined;
  let errorResponse = undefined;
  const id = data.id || 0;
  response = await getAxiosInstance(`/gestores/${id}`)
    .delete()
    .catch((error) => {
      errorResponse = error.response || undefined;
    });
  if (handleResponse) handleResponse(response || errorResponse);
  if (handleAction) handleAction();
}

async function getResumoDados(data, handleResponse, handleAction) {
  let response = undefined;
  let errorResponse = undefined;
  const gestorId = data.gestorId || 0;
  const query = makeQuery(data);
  response = await getAxiosInstance(`/gestores/dados/${gestorId}/resumoDados${query}`)
    .get()
    .catch((error) => {
      errorResponse = error.response || undefined;
    });
  if (handleResponse) handleResponse(response || errorResponse);
  if (handleAction) handleAction();
}

async function getRelatorioAneel(data, handleResponse, handleAction) {
  let response = undefined;
  let errorResponse = undefined;
  const query = data.query ? data.query : "";
  const gestorId = data.gestorId || 0;
  response = await getAxiosInstance(
    `/gestores/${gestorId}/relatorioAneel/${data.dataInicial}/${data.dataFinal}?${query}`
  )
    .get()
    .catch((error) => {
      errorResponse = error.response || undefined;
    });
  if (handleResponse) handleResponse(response || errorResponse);
  if (handleAction) handleAction();
}
