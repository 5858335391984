import moment from 'moment'

export function sortByDate(data = [], attr = 'data_coleta') {
    try {
        return data.sort((a, b) => {
            if (moment(a[attr]).isAfter(moment(b[attr]))) {
                return 1
            } else if (moment(a[attr]).isBefore(moment(b[attr]))) {
                return -1
            }
            return 0
        })
    } catch (ex) {
        return data
    }
}

export function formatValueByType(value, type) {
    switch (type) {
        case 'money':
            return formatDecimal(value, 2)
        case 'money2':
            return formatDecimal(value, 3)
        case 'date':
            let ret = value ? moment(value).format('DD/MM/YYYY') : ""
            return ret
        case 'date_time':
            return value ? moment(value).format('DD/MM HH:mm:ss') : ""
        case 'status':
            let status = convertStatusToText(value)
            return status
        case 'precision2':
            return formatDecimal(value)
        case 'text':
            return value
        default:
            return value
    }
}

export function formatDateTime(dateTime, ignoreHours, breakHourLine, monthly) {
    ignoreHours = ignoreHours == true;
    const formatType = ignoreHours ? 'DD/MM' : 'DD/MM-HH:mm';
    let data = moment(dateTime).format(formatType);

    if (breakHourLine) {
        data = data.replace(":", "\n");
    }

    if (monthly){
        data = moment(dateTime).format('MMMM');
    }
    return data;
}

export function convertStatusToNumber(status) {
    if (status === "ATIVO" || status === "ATIVA" ||
        status === "ABERTO" || status === "ABERTA"
        || status === 1) {
        return 1
    } else {
        return 0
    }
}

export function convertStatusToText(status) {
    if (status === 1) {
        return "ATIVO"
    } else {
        return "INATIVO"
    }
}

export function formatCPF(cpf) {
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function clearCPF(cpf) {
    return cpf.replace(/-/g, '').replace(/\./g, '')
}

export function clearAllMask(value) {
    return value.replace(/-/g, '')
        .replace(/\./g, '')
        .replace(/\(/g, '')
        .replace(/\)/g, '')
        .replace(/-/g, '')
        .replace(/\//g, '')
}

export function formatName(value) {
    let name = value.split(" ")
    name = `${name[0]} ${name[1] && name[1].length > 3 ? name[1] : name[2] || ""}`
    return name
}

/**
 * Number.prototype.format(n, x, s, c)
 *
 * @param integer n: length of decimal
 * @param integer x: length of whole part
 * @param mixed   s: sections delimiter
 * @param mixed   c: decimal delimiter
 */
/* eslint-disable-next-line */
Number.prototype.format = function (n, x, s, c) {
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
        num = this.toFixed(Math.max(0, ~~n));
    return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
};

export function formatMoney(value, decimal = 2) {
    if (value >= 0) {
        return `R$${Number(value).format(decimal, 3, '.', ',')}`
    } else {
        return Number(value).format(decimal, 3, '.', ',').replace('-', '-R$')
    }
}

export function formatDecimal(value) {
    var setDecimal = {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    };
    if (value === null || value === undefined)
        return " -- ";
    else
        return parseFloat(value).toLocaleString("de-DE", setDecimal);
}

export function floorTo2(value) {
    return Math.floor(value * 100) / 100
}
