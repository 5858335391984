import moment from "moment";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  CloudDownloadRounded,
} from "@material-ui/icons";

import { gestorService } from "../../services/gestor_service";
import RelatorioFiltros from "./filtros";
import LineChart from "../../components/LineChart";
import { coletaService } from "../../services/coleta_service";
import { Button, Grid, Typography } from "@material-ui/core";
import ObjectTable from "../../default/table/ObjectTable";
import { PictureAsPdf } from "@material-ui/icons";
import { colors } from "../../constants/dc_constants";
import {
  headRowsAguaDetalhado,
  headRowsAguaRelatorioDados
} from "../../constants/headRows";
import FechamentoView from "../_fechamento/FechamentoView";
import {
  closeProgressDialog,
  makeProgressDialog,
} from "../../default/dialogs/DialogRefInterface";
import {
  htmlToPng,
  template_completo,
} from "../../default/templates_pdf/template_relatorio";
import { toast } from "react-toastify";
import { formatDate } from "../../util/relatorio_utils";

function RelatoriosWater(selected) {
  const AGUA_CONSUMO_ID = "agua_consumo_id";
  const AGUA_DETALHADO_ID = "agua_detalhado_id";
  const AGUA_GERENCIAL_ID = "agua_gerencial_id";
  const RELATORIO_DADOS_ID = "relatorio_dados_id";

  const LOADING_CONSUMO = "Carregando consumo...";
  const LOADING_DADOS = "Carregando dados...";

  const [filters, setFilters] = useState({
    dataInicial: moment().subtract(1, "month").format("YYYY-MM-DD 00:00"),
    dataFinal: moment().format("YYYY-MM-DD 23:59"),
    gestorId: selected?.id ?? null,

    mostrarAguaDetalhado: false,
    mostrarAguaGerencial: false,
    mostrarRelatorioDados: false,
  });

  const [dadosColetas, setDadosColetas] = useState([]);
  const [consumoTotal, setConsumoTotal] = useState([]);

  const [gestores, setGestores] = useState([]);

  const [gestorSelecionado, setGestorSelecionado] = useState(null);

  const [loadings, setLoadings] = useState([]);

  const consumoChartRef = useRef(null);

  const [consumoChartPng, setConsumoChartPng] = useState(null);

  const updateChartsByRef = () => {
    if (consumoChartRef?.current) {
      consumoChartRef.current.updateSelecteds();
    }
  };

  const loadDadosColetas = useCallback(() => {
    try {
      setLoadings([...loadings, LOADING_DADOS]);
      const queryData = {
        gestor_id: filters.gestorId,
        data_inicial: moment(filters.dataInicial).format("YYYY-MM-DDTHH:mm:ss"),
        data_final: moment(filters.dataFinal).format("YYYY-MM-DDTHH:mm:ss"),
        attr: [
          "id",
          "data_coleta",
          "consumo",
          "consumo_acc",
          "bomba_ligada",
          "type_id",
          "messageID",
          "createdAt",
          "updatedAt"
        ],
      };
      coletaService.getColetasAgua(queryData, (response) => {
        if (response.status === 200) {
          const { data } = response;

          let acumulado = data.groupedByMonth.reduce((x, valor) => x + valor.consumptionSum , 0);
          setConsumoTotal(acumulado)
          setDadosColetas(data);
          updateChartsByRef();

          setTimeout(() => {
            setLoadings((loadings) =>
              loadings.filter((loading) => loading !== LOADING_DADOS)
            );
          }, 2000);
        }
      });
    } catch (error) {
      setLoadings((loadings) =>
        loadings.filter((loading) => loading !== LOADING_DADOS)
      );
    }
  }, [filters, setDadosColetas, setLoadings, loadings]);

  const loadGestores = async () => {
    gestorService.getGestores("water",{}, (response) => {
      if (response.status === 200) {
        setGestores(response.data);
      }
    });
  };

  //Metodo usado para imprimir o relatorio.
  const printRelatorio = useCallback(async () => {
    try {
      makeProgressDialog(null, "Gerando relatório...").current.open();
      const dadosDetalhado = {
        show: filters.mostrarAguaDetalhado,
        data: dadosColetas.ungrouped,
        headers: headRowsAguaDetalhado,
        title: "Relatorio de consumo detalhado",
      };

      const dadosEquipamento = {
        show: filters.mostrarRelatorioDados,
        data: dadosColetas,
        headers: headRowsAguaRelatorioDados,
        title: "Relatório Dados do Equipamento",
      };

      let divConsumoAguaPng;

      if (filters.mostrarAguaDetalhado) {
        divConsumoAguaPng = await htmlToPng(
          document.getElementById(AGUA_CONSUMO_ID)
        );
      }

      const doc = template_completo({
        divConsumoAguaPng,
        dadosDetalhado,
        consumoChartPng,
        headerParams: {
          orientation: "p",
          title: " ",
          data_hora: moment().format("DD/MM/YYYY - HH:mm:ss"),
          //equipamento: gestorSelecionado?.nome || "",
          local: gestorSelecionado?.fazenda || "",
          regiao: gestorSelecionado?.regiao || "",
          data_inicio: moment(filters.dataInicial).format(
            "DD/MM/YYYY - HH:mm:ss"
          ),
          data_final: moment(filters.dataFinal).format("DD/MM/YYYY - HH:mm:ss"),
        },
      });

      //função que retorna o nome do arquivo
      const getReportName = () => {
        var nomeArquivo = "Agua";

        //Lista com todos os metodos
        var filterList = [{
          selected: filters.mostrarAguaDetalhado, text: "Consumo"
        },
        {
          selected: filters.mostrarAguaGerencial, text: "Grafico"
        }
        ];

        filterList.map((filter) =>
          nomeArquivo += filter.selected ? (nomeArquivo.length > 0 ? `_${filter.text}` : filter.text) : ""
        )

        return nomeArquivo;
      }

      //os condicionais acima incrementam o nome do arquivo com os tipos de relatorios que foram gerados.

      //Metodo que imprime o relatorio. Valor entre parenteses refere-se ao nome que esse arquivo deve ter.
      doc.save(getReportName());

      closeProgressDialog();
    } catch (error) {
      console.log(error);
      closeProgressDialog();
    }
  }, [
    consumoChartPng,
    filters,
    gestorSelecionado,
    dadosColetas,
  ]);

  const onSubmit = (filters) => {
    setConsumoChartPng(null);
    setFilters(filters);
  };

  const onChangeFilters = (filters) => {
    setDadosColetas([]);
  };

  useEffect(() => {
    if (
        filters.mostrarAguaDetalhado
        || filters.mostrarAguaGerencial
        || filters.mostrarRelatorioDados
      ) {
      loadDadosColetas();
    }
  }, [filters]);

  useEffect(() => {
    let gestor = gestores.find((gestor) => gestor.id === filters.gestorId);
    if(gestor == undefined && selected) gestor = selected;
    setGestorSelecionado(gestor);
  }, [filters.gestorId]);

  useEffect(() => {
    loadGestores();
  }, []);

  useEffect(() => {
    if (loadings.length > 0) {
      makeProgressDialog(null, loadings[loadings.length - 1]).current.open();
    } else {
      closeProgressDialog();
    }
  }, [loadings]);

  let dadosTableRef = React.createRef();
  // let aneelTableRef = React.createRef();
  // let dadosTableRef = React.createRef();
  // let economiaTableRef = React.createRef();

  const toolActions = [
    {
      title: "Baixar XLS",
      label: "Baixar",
      color: colors.BLUE,
      MaterialIcon: CloudDownloadRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        // if (aneelTableRef && aneelTableRef.current) {
        //   aneelTableRef.current.getExportRowsXls(null, true, false);
        // }

        // if (dadosTableRef && dadosTableRef.current) {
        //   dadosTableRef.current.getExportRowsXls(null, true, false);
        // }

        // if (economiaTableRef && economiaTableRef.current) {
        //   economiaTableRef.current.getExportRowsXls(null, true, false);
        // }
      },
    },
  ];

  const RelatorioDadosMemo = useMemo(() => {
    var ungrouped = dadosColetas?.ungrouped;

    return filters.mostrarRelatorioDados && ungrouped?.length > 0 ? (
      <div id={RELATORIO_DADOS_ID} style={{ marginTop: 30 }}>
        <ObjectTable
          orderBy="data_coleta"
          order="desc"
          tableName={"Relatório de Dados do Equipamento"}
          headRows={headRowsAguaRelatorioDados || []}
          rows={ungrouped}
          actions={[]}
          selectedToolActions={[]}
          toolActions={toolActions}
          rowSize={40}
          rowsOptions={[40]}
          perPage={40}
          ref={dadosTableRef}
        />
      </div>
    ) : (
      <></>
    );
  }, [dadosColetas, filters.mostrarRelatorioMassa]);

  const AguaGerencialMemo = useMemo(() => {
    var groupedByDay = dadosColetas?.groupedByDay;

    return filters.mostrarAguaGerencial && groupedByDay?.length > 0 ? (
      <Grid
        id={AGUA_GERENCIAL_ID}
        container
        spacing={3}
        style={{ marginTop: 30 }}
      >
        <Grid item xs={12}>
          {" "}
          <Typography style={{ fontSize: 20, fontWeight: "bold" }} align="left">
            Relatório Gerencial (Gráficos)
          </Typography>{" "}
        </Grid>


        <Grid item xs={12}>
          <Typography style={{ fontSize: 16, fontWeight: "bold" }} align="left">
            Consumo
          </Typography>{" "}
          <Grid item xs={12}>
            <LineChart
              ref={consumoChartRef}
              chartType={"LineChart"}
              data={formatDate(groupedByDay)}
              selected={[gestorSelecionado]}
              chartSize={400}
              chartSelecteds={["aguaConsumoD"]}
              filter={false}
              ignoreHours={true}
              isStacked={true}
              slantedText={false}
              device={"water"}
              onChartReady={(chart) => {
                setConsumoChartPng(chart.getImageURI());
              }}
            />
          </Grid>
        </Grid>
      </Grid>

    ) : (
      <></>
    );
  }, [dadosColetas, filters.mostrarRelatorioGerencial]);

  const AguaDetalhadoMemo = useMemo(() => {
    var ungrouped = dadosColetas?.ungrouped;

    return filters.mostrarAguaDetalhado && ungrouped?.length > 0 ? (
      <div>
      <div id={AGUA_CONSUMO_ID} style={{ marginTop: 30 }}>
        <FechamentoView
          data={consumoTotal}
          gestor={gestorSelecionado}
          dataFinal={filters.dataFinal}
          dataInicial={filters.dataInicial}
        />
      </div>
      <div id={AGUA_DETALHADO_ID} style={{marginTop: 30}}>
        <ObjectTable
          orderBy="data_coleta"
          order="desc"
          tableName={"Consumo detalhado"}
          headRows={headRowsAguaDetalhado || []}
          rows={ungrouped}
          actions={[]}
          selectedToolActions={[]}
          toolActions={[]}
          rowSize={40}
          rowsOptions={[40]}
          perPage={40}
        />
      </div>
      </div>
    ) : (
      <></>
    );
  }, [dadosColetas, filters.mostrarAguaDetalhado]);

  // const Agua = useMemo(() => {
  //   return filters.mostrarRelatorioFechamento &&
  //     Object.keys(dadosRelatorioFechamento).length > 0 ? (
  //     <div id={RELATORIO_FECHAMENTO_ID} style={{ marginTop: 30 }}>
  //       <Typography style={{ fontSize: 20, fontWeight: "bold" }} align="left">
  //         Relatório de Rateio de Consumo
  //       </Typography>{" "}
  //       <FechamentoView
  //         data={dadosRelatorioFechamento}
  //         gestor={gestorSelecionado}
  //         dataFinal={filters.dataFinal}
  //         dataInicial={filters.dataInicial}
  //       />
  //     </div>
  //   ) : (
  //     <></>
  //   );
  // }, [dadosRelatorioFechamento, filters.mostrarRelatorioFechamento]);

  return (
    <div>
      <RelatorioFiltros
        selected={selected}
        onSubmit={onSubmit}
        onChangeFilters={onChangeFilters}
        gestores={gestores}
      />

      {(dadosColetas.ungrouped?.length > 0) && (
          <Grid container display="flex">
            <Button
              variant="contained"
              style={{
                height: 40,
                backgroundColor: colors.DARK_GREY,
                color: "white",
                marginTop: 14,
                boxShadow: "none"
              }}
              onClick={printRelatorio}
            >
              Exportar <PictureAsPdf style={{marginLeft: 7.5}}/>
            </Button>
          </Grid>
        )}

      {AguaGerencialMemo}
      {AguaDetalhadoMemo}
      {RelatorioDadosMemo}
    </div>
  );
}

export default RelatoriosWater;
