import React, { createRef } from "react";
import {
  Map,
  TileLayer,
  Marker,
  Popup,
  Tooltip,
  ZoomControl,
} from "react-leaflet";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import "leaflet/dist/leaflet.css";
import L, { marker } from "leaflet";
import ReactLeafletSearch from "react-leaflet-search";
import {
  Grid,
  IconButton,
  Typography
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { gestorService } from "../services/gestor_service";
import {
  BarChartRounded,
  NetworkCheck,
  ReceiptRounded,
  UpdateOutlined,
  FilterListOutlined
} from "@material-ui/icons";
import { colors } from "../constants/dc_constants";
import {
  makeActionFormDialog,
  makeAlertDialog,
} from "../default/dialogs/DialogRefInterface";
import ChartGestorOn from "../projectPower/_graficos/ChartGestorOn";
import ChartGestorOnWater from "../projectWater/_graficos/ChartGestorOn";
import ChartGestor from "../projectPower/_graficos/ChartGestor";
import ChartGestorW from "../projectWater/_graficos/ChartGestor";
import TableRelatorioAneel from "./TableRelatorioAneel";
import TableRelatorioMassa from "./TableRelatorioMassa";
import FilterRelatorio from "./FilterRelatorio";
import ShowGestor from "./ShowGestor";
import FormGestor from "./FormGestor";
import { validateShowResponseToast } from "../helpers/toast_alerts";
import { buildCustomSymbol } from "../util/buildMapPin";
import { checkOnline, colorPin, isOnlineDate } from "../util/gestor_utils";
import { convertData } from "../util/abb_convert";
import { mediaTensao, mediaCorrente } from "../util/gestor_utils";

import "./style.css";
import moment from "moment";
import CustomIconButton from "../components/CustomIconButton";
import RelatoriosWater from "../projectWater/_relatorios";
import { coletaService } from "../services/coleta_service";
import Relatorios from "../projectPower/_relatorios";

const pinIcon = (isOnline = true, corPin, pinId) => {
  // #00b81f
  return new L.Icon({
    iconUrl: buildCustomSymbol(corPin, colorPin(isOnline)),
    iconAnchor: [20, 50],
    popupAnchor: [0, -44],
    iconSize: [51, 60],
    className: pinId
  });
};

const popupContent = {
  "background-color": "#201f29",
};

class MapaGestor extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      position: [-18, -48],
      position2: [-17, -47],
      zoom: 4,
      gestores: [],
      data: [],
      potenciaAtiva: 0,
      potenciaReativa: 0,
      potenciaAparente: 0,
      mediaTensao: 0,
      mediaCorrente: 0,
      selectedEquipment: null
    };
  }
  mapRef = createRef();
  plugin = createRef();

  handleLoadGestores = () => {
    gestorService.getGestores(null,null, (response) => {
      if (response && response.status === 200) {
        this.setState({
          gestores: response.data,
        });
      }
    });
  };

  handleOpenGestorOnline = (event, selected) => {
    event.stopPropagation();
    makeActionFormDialog(
      selected.produto == "power" ? ChartGestorOn : ChartGestorOnWater,
      `Painel Online`,
      true,
      selected,
      null,
      null
    ).current.open();
  };

  handleOpenChart = (event, selected) => {
    event.stopPropagation();
    makeActionFormDialog(
      selected.produto == "power" ? ChartGestor : ChartGestorW,
      `Gráficos do Medidor: ${selected.id_equipamento} - ${selected.fazenda}`,
      true,
      selected,
      null,
      null
    ).current.open();
  };

  handleOpenRelatorio = (event, selected) => {
    event.stopPropagation();
    switch(selected.produto){
      case "power":
        makeActionFormDialog(
          () => Relatorios(selected),
          `Relatorios do Medidor: ${selected.id_equipamento} - ${selected.fazenda}`,
          true,
          null,
          null,
          null,
          ).current.open();
        break;
      case "water":
        makeActionFormDialog(
          () => RelatoriosWater(selected),
          `Relatorios do Medidor: ${selected.id_equipamento} - ${selected.fazenda}`,
          true,
          null,
          null,
          null,
          ).current.open();
        break;
    }
  };

  handleShow = (event, selected) => {
    event.stopPropagation();
    makeActionFormDialog(
      ShowGestor,
      "Dados do Gestor",
      false,
      selected,
      "",
      () => {}
    ).current.open();
  };

  handleEdit = (event, selected) => {
    event.stopPropagation();
    makeActionFormDialog(
      FormGestor,
      "Editar Gestor",
      null,
      selected,
      "Salvar",
      (data) => {
        gestorService.updateGestor(data, (response) => {
          if (validateShowResponseToast(response)) {
            this.handleLoadGestores();
          }
        });
      }
    ).current.open();
  };

  handleDelete = (event, selected) => {
    event.stopPropagation();
    makeAlertDialog(
      () => {
        const data = { id: selected.id };
        gestorService.deleteGestor(data, (response) => {
          if (validateShowResponseToast(response)) {
            this.handleLoadGestores();
          }
        });
      },
      "Atenção",
      "Deseja realmente excluir este Gestor? Esta ação só poderá ser revertida por um administrador do banco de dados!"
    ).current.open();
  };

  formatTextNaN = (text) => {
    if (isNaN(text) || text == "" || text == undefined) {
      return 0;
    }

    return text;
  };

  getUltimaColetaFormatedDate = () => {
    try {
      return moment(this.state.ultimaColeta.data_coleta).format(
        "DD/MM/YYYY HH:mm:ss"
      );
    } catch (error) {
      return "";
    }
  };

  updateUIData = (responseData) => {
    try {
      let data = {};
      let data_coleta = "";
      switch (responseData.produto){
        case "power":
          data = convertData(responseData.coletas, responseData);

          const ultimaColeta = data[1];
          const potenciaAtiva = ultimaColeta.potencia_ativa_total - ultimaColeta.potencia_injetada;
          const fatorPotencia = ultimaColeta.fator_potencia_total;
          const potenciaAparente = ultimaColeta.potencia_aparente_total;
          const potenciaReativa = ultimaColeta.potencia_reativa_total;
          data_coleta = ultimaColeta.data_coleta;
          const tensao = mediaTensao(
            ultimaColeta.tensao_l1_l2,
            ultimaColeta.tensao_l2_l3,
            ultimaColeta.tensao_l3_l1
          );
          const corrente = mediaCorrente(
            ultimaColeta.corrente_i1,
            ultimaColeta.corrente_i2,
            ultimaColeta.corrente_i3
          );
          return {
            corrente,
            potenciaAtiva,
            potenciaReativa,
            potenciaAparente,
            fatorPotencia,
            tensao,
            data_coleta,
          };
        case "water":
          let consumoAtual = responseData.coletasAguas[0].consumo;
          data_coleta = responseData.coletasAguas[0].data_coleta;
          return {
            consumoAtual,
            data_coleta
          }
        }
    } catch (error) {
      console.error(error)
    }
  };

  async componentDidMount() {
    try {
      this.handleLoadGestores();
    } catch (error) {}
  }

  getEquipamentDataList(produto, type, data, cor) {
    let dataList = [];

    switch (produto){
      case 'POWER':
        switch (type) {
          case 'ABB':
            dataList.push(
              {text: `Tensão : ${this.formatTextNaN(data.tensao).toFixed(2)} V`, icon: "FlashOn" }
            )
            dataList.push(
              {text: `Corrente : ${this.formatTextNaN(
                data.corrente
              ).toFixed(2)} A`, icon: "Speed" }
            )
            break;

          case 'ABNT':
            dataList.push(
              {text: `Fator de Potência : ${this.formatTextNaN(Math.abs(data.fatorPotencia)).toFixed(2)} ${data.fatorPotencia < 0 ? " c" : " i"}`, icon: "Speed" }
            )
            dataList.push(
              {text: `Potência Aparente : ${this.formatTextNaN(
                data.potenciaAparente
              ).toFixed(2)} S`, icon: "FlashOn" }
            )
            break;
        }
        dataList.push(
          {text: `Potência Ativa  :  ${this.formatTextNaN(
            Math.abs(data.potenciaAtiva)
          ).toFixed(2)} KW ${data.potenciaAtiva < 0 ? `Injetado`:``}`, icon: "TrendingUp" }
        )

      break;
      case 'WATER':
        dataList.push(
          {text: `Consumo atual: ${this.formatTextNaN(data.consumoAtual)} m³`,
          icon: "WaterDrop",
          color: cor
        }
        )
      break;
    }

    dataList.push(
      {text: moment(data.data_coleta).format(
        "DD/MM/YYYY HH:mm:ss"
      ),
      icon: "UpdateOutlined",
      color: isOnlineDate(data.data_coleta) ? "green" : "red"}
    )

    return dataList;
  }

  showGestores = () => {
    try {
      return this.state.gestores.map((gestor) => {
        let isOnline = null;
        let cor = colors.DARK_GREY;
        let corPin = "#201E29";

        if (gestor.produto == "power" && gestor.coletas[0]) {
          isOnline = checkOnline(gestor.coletas[0]);
          cor = colors.ORANGE_POWER;
        } else if (gestor.produto == "water" && gestor.coletasAguas[0]){
          isOnline = checkOnline(gestor.coletasAguas[0]);
          cor = colors.BLUE;
          corPin = colors.BLUE;
        }

        const data = this.updateUIData(gestor);
        let pin = `pin${gestor.id}`;

        return (
          <Marker key={gestor.id} position={[gestor.lat, gestor.long]} icon={pinIcon(isOnline, corPin, pin)}>
            <Popup minWidth={300}>
              <Grid
                alignItems={"center"}
                justifyContent={"center"}
                alignContent={"center"}
                container
                spacing={1}
              >
                <Grid item xs={12}>
                  <Typography
                    style={{ textTransform: "uppercase", fontWeight: 700, fontSize: "15px" }}
                    component="div"
                    variant="caption"
                  >
                    {`${gestor.fazenda}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    style={{ fontWeight: 500 }}
                    component="div"
                    variant="caption"
                  >
                    {gestor.nome_bombeamento && `${gestor.nome_bombeamento}`}
                  </Typography>
                </Grid>
                <Grid
                  // alignItems={"center"}
                  // justifyContent={"center"}
                  // alignContent={"center"}
                >
                  <Typography
                    style={{ textTransform: "uppercase", fontWeight: 500, color: cor, height: "18px" }}
                    component="div"
                    variant="caption"
                  >
                    {`${gestor.equipamento}`}
                  </Typography>
                </Grid>
                {data ? (
                  <Grid
                    alignItems={"flex-start"}
                    justifyContent={"flex-start"}
                    alignContent={"flex-start"}
                    container
                    spacing={1}
                    style={{
                      border: '2px solid',
                      borderColor: cor,
                      borderRadius: "15px",
                      bordeStyle: "outset",
                      marginLeft: "3px",
                      marginBottom: "5px"
                    }}
                  >
                    {
                      this.getEquipamentDataList(gestor.produto.toUpperCase(), gestor.equipamento.toUpperCase(), data, cor).map((element, index) => {
                        return (
                          <CustomIconButton
                            text = {element.text}
                            icon = {element.icon}
                            color = {element.color}
                            key = {`icon${index}`}
                          />
                        );
                      })
                    }
                  </Grid>
                ) : (
                  " : Sem Dados"
                )}
                <Grid
                  justifyContent={"center"}
                  alignContent={"center"}
                  container
                  spacing={1}
                >
                  <Grid item xs={2}>
                    <IconButton
                      // onMouseEnter={{ backgroundColor: colors.ORANGE_POWER }}
                      style={{ color: colors.BLUE }}
                      onClick={(event) =>
                        this.handleOpenGestorOnline(event, gestor)
                      }
                    >
                      <NetworkCheck />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      style={{ color: colors.BLUE }}
                      onClick={(event) => this.handleOpenChart(event, gestor)}
                    >
                      <BarChartRounded />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2}>
                    <IconButton
                      style={{ color: colors.BLUE }}
                      onClick={(event) =>
                        this.handleOpenRelatorio(event, gestor)
                      }
                    >
                      <ReceiptRounded />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Popup>
            <Tooltip sticky>
              <Typography
                style={{ color: isOnline ? "green" : "red" }}
                variant="h6"
              >
                {
                  `${gestor.id_equipamento} - ${gestor.fazenda} : ${isOnline ? "online" : "offline"}`
                }
              </Typography>{" "}
            </Tooltip>
          </Marker>
        );
      });
    } catch (error) {
      console.log(error);
    }
  };

  customProvider = () => {
    try {
      return this.state.gestores.map((gestor) => {
        return {
          id: gestor.id,
          name: `${gestor.id_equipamento} - ${gestor.fazenda}`,
          coordinates: [Number(gestor.lat), Number(gestor.long)],
        };
      })
    }
    catch (error){
      console.log(error)
    }
  };

  handleLocationSelect = async (gestorSelected) => {
    const gestorId = gestorSelected.target.value;
    const gestorLocation = this.customProvider().find((gestor) => gestor.id == gestorId);
    const newLocation = gestorLocation.coordinates;

    this.setState({ selectedEquipment: gestorId });

    const { current = {} } = this.mapRef;
    const { leafletElement: map } = current;
    const fazendaLatLng = [(newLocation[0] + 0.005), newLocation[1]] || [0, 0];

    const pinSelected = document.querySelector(`.pin${gestorId}`);

    const event = new MouseEvent('click', {
      bubbles: true,
      cancelable: true,
    });

    if (location) {
      await map.flyTo(fazendaLatLng, 15, { duration: 2 });
      // map.on('zoomend', function () {
      //   pinSelected.dispatchEvent(event);
      // });
      pinSelected.dispatchEvent(event);
      return;
    }

    // Sets map to its default position
    map.flyTo([-18, -48], 4, {duration: 2});

    // return (!location)
    //   ? map.flyTo([-18, -48], 4, {duration: 2})
    //   : map.flyTo(fazendaLatLng, 15, {duration: 2});
  };

  render() {
    // const customProvider = {
    //   search: async (inputValue) => {
    //     const values = this.state.gestores
    //       .map((gestor) => {
    //         return {
    //           latitude: Number(gestor.lat),
    //           longitude: Number(gestor.long),
    //           name: `${gestor.id_equipamento} - ${gestor.fazenda}`,
    //         };
    //       })
    //       .filter((value) => {
    //         return value.name.toLowerCase().includes(inputValue.toLowerCase());
    //       });
    //     return {
    //       info: values,
    //     };
    //   },
    // };

    return (
      <>
        <div className="customSearch">
          <FilterListOutlined />
          <FormControl className="cs__formControl">
            <InputLabel htmlFor="gestor">Selecione um medidor</InputLabel>
            <Select
              value={this.state.selectedEquipment || ""}
              onChange={this.handleLocationSelect}
              className="cs__select"
              name="gestor"
            >
              {this.customProvider().map((gestor) => (
                <MenuItem key={gestor.id} value={gestor.id}>
                  {gestor.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <Map
          style={{ height: "calc(100vh - 64px)", width: "100%" }}
          center={this.state.position}
          zoom={this.state.zoom}
          maxZoom={17}
          minZoom={2}
          zoomControl={false}
          useFlyTo={true}
          ref={this.mapRef}
        >
          <TileLayer
            attribution="Tiles &copy; Esri"
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}"
          />
          {this.showGestores()}
          <ZoomControl position="bottomright" />
          {/* <ReactLeafletSearch
            position="topright"
            inputPlaceholder="Buscar Equipamento ou Fazenda"
            zoom={15}
            className="custom-style"
            showMarker={true}
            showPopup={true}
            openSearchOnLoad={true}
            closeResultsOnClick={false}
            customProvider={customProvider}
          /> */}
        </Map>
      </>
    );
  }
}

export default MapaGestor;
