import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import moment from "moment";
import { formatValueByType, formatMoney } from "./formatter";
import { CSVLink } from "react-csv";
import EnhancedTableHead from "./EnhancedTableHead";
import ObjectTableToolbar from "./ObjectTableToolbar";
import { template_table } from "../templates_pdf/template_table";
import ExportXLS from "../templete_xls/ExportXlsx";
import { makeSum } from "../../util/relatorio_utils";
import { findValue } from "./table_util";
import FormatValue from "./FormatValue";
import { Action } from "./actions";
import { Tbody, Tr, Td } from "react-super-responsive-table";
import { colors } from "../../constants/dc_constants";

class ObjectTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actions: this.props.actions || [],
      iconHead: this.props.materialIconHead,
      selectedToolActions: this.props.selectedToolActions || [],
      toolActions: this.props.toolActions || [],
      rowId: this.props.rowId || "",
      rowsPerPage: this.props.rowSize || 10,
      rowsPerPageOptions: this.props.rowsOptions || [5, 10, 15],
      emptyRows: 0,
      page: 0,
      order: this.props.order || "asc",
      orderBy: this.props.orderBy || "id",
      selected: [],
      dense: this.props.dense || true,

      isExport: false,
      isExportSelected: false,
      csvHeaders: [],
      rowsSum: [],
    };

    this.handleChangeDense = this.handleChangeDense.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.handleClearSelected = this.handleClearSelected.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleSelectAllClick = this.handleSelectAllClick.bind(this);
    this.handleRequestSort = this.handleRequestSort.bind(this);
    this.linkCSVRef = React.createRef();
  }

  exportCSV = (
    csvHeaders = undefined,
    isExport = true,
    isExportSelected = false,
    rowsSum = []
  ) => {
    if (!csvHeaders)
      csvHeaders = this.props.headRows.map((hr) => {
        return { label: hr.label, key: hr.id };
      });
    this.setState(
      {
        csvHeaders: csvHeaders,
        isExport: isExport,
        isExportSelected: isExportSelected,
        rowsSum: rowsSum,
      },
      () => {
        if (this.linkCSVRef.current) {
          this.linkCSVRef.current.link.click();
        }
      }
    );
  };

  exportPDF = (
    headRows,
    isExportSelected = false,
    pdfName = `${this.props.tableName}_${moment().format("DD_MM_YYYY")}`,
    orientation = "p",
    title = "",
    rowsSum = [],
    rowId = ""
  ) => {
    this.setState(
      {
        isExportSelected: isExportSelected,
      },
      () => {
        template_table(
          this.getExportRowsPDF(),
          headRows,
          orientation,
          title,
          rowsSum,
          rowId
        ).save(pdfName);
      }
    );
  };

  updateTable = (newState = {}) => {
    this.setState({ ...newState });
  };

  getExportRowsPDF = () => {
    const exportRows = this.props.rows.filter((row) => {
      if (this.state.isExportSelected) {
        return this.state.selected.indexOf(row[this.state.rowId]) > -1;
      }
      return true;
    });
    return exportRows;
  };

  getExportRowsXls = () => {
    let exportRows = this.props.rows.filter((row) => {
      if (this.state.isExportSelected) {
        return this.state.selected.indexOf(row[this.state.rowId]) > -1;
      }
      return true;
    });

    if (this.state.rowsSum.length > 0) {
      exportRows.push(
        makeSum(
          exportRows,
          this.state.rowsSum,
          this.props.headRows,
          this.state.rowId
        )
      );
    }

    exportRows = exportRows.map((row) => {
      let lRow = { ...row };
      this.props.headRows.forEach((hr) => {
        let value = formatValueByType(lRow[hr.id], hr.type);
        lRow[hr.id] = value === "NaN" ? "----" : value;
      });
      return lRow;
    });

    let xlsRows = [];

    if (this.props.headRows && this.props.headRows.length > 0) { // get headRow name
      for (let index = 0; index < exportRows.length; index++) {
        const element = exportRows[index];
        let row = {};

        for (let headRowIndex = 0; headRowIndex < this.props.headRows.length; headRowIndex++) {
          const headRow = this.props.headRows[headRowIndex];
          row[headRow.label] = element[headRow.id];
        }

        xlsRows[index] = row;

      }
    } else {
      xlsRows = exportRows;
    }

    ExportXLS(
      xlsRows,
      `${this.props.tableName}_${moment().format("DD_MM_YYYY")}`
    );
  };

  desc = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  getExportRows = () => {
    let exportRows = this.props.rows.filter((row) => {
      if (this.state.isExportSelected) {
        return this.state.selected.indexOf(row[this.state.rowId]) > -1;
      }
      return true;
    });
    if (this.state.rowsSum.length > 0) {
      exportRows.push(
        makeSum(
          exportRows,
          this.state.rowsSum,
          this.props.headRows,
          this.state.rowId
        )
      );
    }
    exportRows = exportRows.map((row) => {
      let lRow = { ...row };
      this.props.headRows.forEach((hr) => {
        let value = formatValueByType(lRow[hr.id], hr.type);
        lRow[hr.id] = value === "NaN" ? "----" : value;
      });
      return lRow;
    });

    return exportRows;
  };

  getSorting = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => this.desc(a, b, orderBy)
      : (a, b) => -this.desc(a, b, orderBy);
  };

  isSelected = (name) => this.state.selected.indexOf(name) !== -1;

  handleRequestSort(event, property) {
    const isDesc =
      this.state.orderBy === property && this.state.order === "desc";
    this.setState({ order: isDesc ? "asc" : "desc", orderBy: property });
  }

  handleSelectAllClick(event) {
    if (event.target.checked) {
      const newSelecteds = this.props.rows.map((n) => n[this.state.rowId]);
      this.setState({ selected: newSelecteds });
      if (this.props.onSelectedChange)
        this.props.onSelectedChange(newSelecteds);
      return;
    }
    this.setState({ selected: [] });
    if (this.props.onSelectedChange) this.props.onSelectedChange([]);
  }

  handleClick(event, id) {
    event.stopPropagation();
    const selectedIndex = this.state.selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(this.state.selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(this.state.selected.slice(1));
    } else if (selectedIndex === this.state.selected.length - 1) {
      newSelected = newSelected.concat(this.state.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        this.state.selected.slice(0, selectedIndex),
        this.state.selected.slice(selectedIndex + 1)
      );
    }
    this.setState({ selected: newSelected });
    if (this.props.onSelectedChange) this.props.onSelectedChange(newSelected);
  }

  handleClearSelected() {
    this.setState({ selected: [] });
  }

  handleChangePage(event, newPage) {
    if (this.state.page < newPage) {
      if ((newPage + 1) * this.state.rowsPerPage > this.props.perPage) {
        if (this.props.handleLoadPage) {
          newPage = 0;
          this.props.handleLoadPage(1);
        }
      }
    }
    this.setState({ page: newPage });
  }

  handleChangeRowsPerPage(event) {
    this.setState({
      rowsPerPage: event.target.value,
      emptyRows:
        event.target.value -
        Math.min(
          event.target.value,
          this.props.rows.length - this.state.page * event.target.value
        ),
    });
  }
  handleChangeDense(event) {
    this.setState({ dense: event.target.checked });
  }

  handleLabelFromTo = ({ from, to, count }) => {
    const lFrom = this.props.from || undefined;
    return `Mostrando ${lFrom ? lFrom + from - 1 : from}-${
      lFrom ? lFrom + to - 1 : to
    } de ${count} registros`;
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <ObjectTableToolbar
            numSelected={
              this.props.showCheckBox ? this.state.selected.length : 0
            }
            selectedToolActions={this.props.selectedToolActions}
            toolActions={this.props.toolActions}
            selected={this.state.selected}
            tableName={this.props.tableName}
            iconHead={this.state.iconHead}
          ></ObjectTableToolbar>
          <div className={classes.tableWrapper}>
            <Table
              className={classes.table}
              aria-labelledby="tabldfeTitle"
              size={this.state.dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={
                  this.props.showCheckBox ? this.state.selected.length : 0
                }
                order={this.state.order}
                orderBy={this.state.orderBy}
                onSelectAllClick={this.handleSelectAllClick}
                onRequestSort={this.handleRequestSort}
                rowCount={this.props.rows ? this.props.rows.length || 0 : 0}
                headRows={this.props.headRows}
                tableName={this.props.tableName}
                showCheckBox={this.props.showCheckBox}
              />
              <TableBody>
                {this.stableSort(
                  this.props.rows,
                  this.getSorting(this.state.order, this.state.orderBy)
                )
                  .slice(
                    this.state.page * this.state.rowsPerPage,
                    this.state.page * this.state.rowsPerPage +
                      this.state.rowsPerPage
                  )
                  .map((row, index) => {
                    const isItemSelected = this.isSelected(
                      row[this.state.rowId]
                    );

                    const labelId = `enhanced-table-checkbox-${index}`;

                    return (
                      <TableRow
                        // key={row[this.state.rowId]}
                        key={index}
                        hover={true}
                        // onClick={(event) =>
                        //   this.handleClick(event, row[this.state.rowId])
                        // }
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        selected={isItemSelected}
                        style={{
                          backgroundColor:
                            (row?.status ?? 1) == 0 ? '#D3D3D3' :
                              (row?.status_energia ?? 1) == 0 ? '#ffc9c9' : ''
                        }}
                      >
                        {this.props.showCheckBox ? (
                          <TableCell
                            key={`tablecell_${index}`}
                            padding="checkbox"
                          >
                            <Checkbox
                              checked={isItemSelected}
                              inputProps={{ "aria-labelledby": labelId }}
                            />
                          </TableCell>
                        ) : (
                          <TableCell key={`div_${index}`}></TableCell>
                        )}

                        {this.props.headRows.map((hr) => {
                          const type = hr.type ? hr.type : undefined;
                          const handlecheckOnlineCustom = hr.handleCustom || undefined;
                          const cellTitle = this.props.cellTitle
                            ? row[this.props.cellTitle]
                            : "";
                          let value = findValue(row, hr.id);
                          if (hr.handleCustom) value = hr.handleCustom(row);
                          if (
                            hr.handleCustom &&
                            hr.handleValue &&
                            type === tableTypes.CUSTOM_ROW
                          ) {
                            const lValue = hr.handleValue(row);
                            row[hr.id] = lValue;
                          } else {
                            row[hr.id] = row[hr.id] ? row[hr.id] : value;
                          }
                          return (
                            <TableCell
                              key={hr.id}
                              align={"center"}
                              title={cellTitle}
                            >
                              <FormatValue
                                value={value}
                                type={type}
                                row={row}
                                handleCustom={handlecheckOnlineCustom}
                              />
                            </TableCell>
                          );
                        })}
                        <TableCell
                          align="right"
                          key={`tablecell2 ${index}`}
                          style={{
                            paddingRight: 20,
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            borderTop: "1px solid #e0e0e0",
                          }}
                        >
                          {this.props.actions.map((act) => {
                            return (
                              <Action
                                key={act.title}
                                title={act.title}
                                badgeCount={act.badgeCount}
                                MaterialIcon={act.MaterialIcon}
                                handleClick={act.handleClick}
                                row={row}
                                color={act.color}
                              />
                            );
                          })}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                {this.state.emptyRows > 0 && (
                  <TableRow style={{ height: 49 * this.state.emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>

          <CSVLink
            target="_blank"
            data={this.state.isExport ? this.getExportRows() : []}
            headers={this.state.csvHeaders}
            ref={this.linkCSVRef}
            filename={`${this.props.tableName}_${moment().format(
              "DD_MM_YYYY"
            )}.csv`}
          ></CSVLink>

          <TablePagination
            rowsPerPageOptions={this.state.rowsPerPageOptions}
            component="div"
            labelRowsPerPage={`${this.props.tableName} por Página`}
            count={this.props.count || this.props.rows.length}
            labelDisplayedRows={this.handleLabelFromTo}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            backIconButtonProps={{
              "aria-label": "Anterior",
            }}
            nextIconButtonProps={{
              "aria-label": "Próxima",
            }}
            onPageChange={this.handleChangePage}
            onRowsPerPageChange={this.handleChangeRowsPerPage}
          />

        </Paper>
        {this.props.showDense ? (
          <FormControlLabel
            control={
              <Switch
                checked={this.state.dense}
                onChange={this.handleChangeDense}
              />
            }
            label="Contrair Células"
          />
        ) : (
          <div />
        )}
      </div>
    );
  }
}

const styles = (theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 300,
  },
  tableWrapper: {
    overflowX: "auto",
    height: "32rem"
  },
  tableRow: {
    "&hover:hover": {
      backgroundColor: "blue",
    },
  },
});

export default withStyles(styles)(ObjectTable)
