import React, { Component } from "react"
import { Router, Route, Switch, Redirect } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import MomentUtils from "@date-io/moment"
import moment from "moment"
import "moment/locale/pt-br"
import "./App.css"
import FormLogin from "./authenticate/FormLogin"
import Dashboard from "./dashboard/Dashboard"
import AlertDialog from "./default/dialogs/AlertDialog"
import { makeAlertDialog } from "./default/dialogs/DialogRefInterface"
import FormRecover from "./authenticate/FormRecover"
import FormSendRecover from "./authenticate/FormSendRecover"
import TokenLogin from "./authenticate/TokenAuth"
import browserHistory from "./helpers/browser_history"
import { userService } from "./services/user_service"
import { GlobalStylesLogin } from '../app/GlobalStyles'

function NotFoundPage() {
  return <h2> 404 - Página Não Encontrada!</h2>
}

function Login({ component: Component, authed, ...rest }) {
  return (
    <>
      <GlobalStylesLogin />
      <Route {...rest}>
        {props =>
          localStorage.getItem("user") !== null ? (
            <Redirect
              to={{ pathname: "/dashboard", state: { from: props.location } }}
            />
          ) : (
            <Component {...props} browserHistory={browserHistory} />
          )
        }
    </Route>
  </>
  )
}

function PrivateRoute({ component: Component, authed, ...rest }) {
  return (
    <>
      <Route {...rest}>
        {props =>
          localStorage.getItem("user") !== null ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/", state: { from: props.location } }} />
          )
        }
      </Route>
    </>
  )
}

class App extends Component {
  render() {
    return (
      <div className="App">
        <MuiPickersUtilsProvider
          libInstance={moment}
          utils={MomentUtils}
          locale={"pt-br"}
        >
          <AlertDialog ref={makeAlertDialog()} />

          <Router history={browserHistory}>
            <Switch>
              <Login path="/auth" component={TokenLogin} />

              <Login
                authed={userService.getAll()}
                exact
                path="/"
                component={FormLogin}
              />

              <Login
                authed={userService.getAll()}
                exact
                path="/recuperar/:token"
                component={FormRecover}
              />
              <Login
                authed={userService.getAll()}
                exact
                path="/recuperar"
                component={FormSendRecover}
              />

              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/gestores"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/agua/gestores"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/usuarios"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/fechamentos"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/fechamentos/novo"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/relatorios"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/agua/relatorios"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/economia"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/logs"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/charts"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/agua/charts"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/esqueleton"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/alertas"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/agua/alertas"
                component={Dashboard}
              />
              <PrivateRoute
                authed={userService.getAll()}
                exact
                path="/dashboard/concessionarias"
                component={Dashboard}
              />
              <Route path="*" component={NotFoundPage} />
            </Switch>
          </Router>

          <ToastContainer
            autoClose={3000}
            position="bottom-right"
            draggable
          ></ToastContainer>
        </MuiPickersUtilsProvider>
      </div>
    )
  }
}

export default App
