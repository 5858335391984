import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { FilterList, Receipt } from "@material-ui/icons";
import moment from "moment";
import { toast } from "react-toastify";
import { colors } from "../../constants/dc_constants";
import CustomDateTimePicker from "../../default/custom/CustomDateTimePicker";
import { coletaService } from "../../services/coleta_service";

export default function RelatorioFiltros({
  selected,
  onChangeFilters,
  onSubmit,
  gestores,
}) {
  const [filters, setFilters] = useState({
    dataInicial: moment().subtract(1, "month").format("YYYY-MM-DD 00:00"),
    dataFinal: moment().format("YYYY-MM-DD 23:59"),
    gestorId: selected?.id ?? null,

    mostrarAguaConsumo: false,
    mostrarAguaDetalhado: false,
    mostrarAguaGerencial: false,
    mostrarRelatorioDados: false,
  });

  var user = JSON.parse(localStorage.getItem("user"));
  var permission = user["permissao"];

  const [gestor, setGestor] = useState(selected);
  const [dateSelected, setDateSelected] = useState(null);

  const getErrorMessage = () => {
    if (!filters.dataInicial) return "Informe a data inicial";
    if (!filters.dataFinal) return "Informe a data final";
    if (!filters.gestorId) return "Informe o medidor";
    if (
      !filters.mostrarAguaConsumo &&
      !filters.mostrarAguaDetalhado &&
      !filters.mostrarAguaGerencial &&
      !filters.mostrarRelatorioDados
    )
      return "Selecione pelo menos um relatório";

    if (
      moment(filters.dataInicial).add(32, "days") < moment(filters.dataFinal)
    ) {
      return "O intervalo de datas deve ser menor que 1 mês";
    }

    return "";
  };

  const ultimas12Faturas = () => {
    const date = new Date();
    const faturas = [];
    for (let i = 0; i < 12; i++) {
      let lastDay = new Date(date.getFullYear(), date.getMonth() + 1 - i, 0);
      faturas.push(moment(lastDay));
    }
    return faturas;
  };

  const handleChangeDateByLastMonth = (date) => {
    const dataFinal = date.format(`YYYY-MM-DD 23:59`);
    const dataInicial = date.format(`YYYY-MM-01 00:00`);

    setFilters({
      ...filters,
      dataInicial,
      dataFinal,
    });
  };

  const handleChangeFilters = (event) => {
    //debugger
    setFilters({
      ...filters,
      [event.target.name]: event.target.value || event.target.checked,
    }
    );

    if (event.target.name === "gestorId") {
      setGestor(gestores.find((g) => g.id === event.target.value));
      setFilters({
        ...filters,
        gestorId: event.target.value,
        mostrarAguaConsumo: false,
        mostrarAguaDetalhado: false,
        mostrarAguaGerencial: false,
        mostrarRelatorioDados: false,
      }
      );
    };
  }

  const handleSubmit = () => {
    const errorMessage = getErrorMessage();
    if (errorMessage) {
      toast.error(errorMessage);
      return;
    }
    if (onSubmit); onSubmit(filters);
  };

  useEffect(() => {
    if (onChangeFilters) onChangeFilters(filters);
  }, [filters]);

  return (
    <Grid container spacing={3}>
      {(selected?.id ?? -1) == -1 &&
      <Grid item xs={12} md={4}>
        <InputLabel key="0" align="left" htmlFor="gestorId">
          Selecione o Medidor :
        </InputLabel>
        <Select
          fullWidth
          align="left"
          value={filters.gestorId || ""}
          name="gestorId"
          onChange={handleChangeFilters}
        >
          {gestores.map((ges) => (
            <MenuItem
              key={ges.id}
              value={ges.id}
            >
              {`${ges.id_equipamento} (${ges.equipamento}) - ${ges.fazenda}`}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      }
      <Grid item xs={12} md={3}>
        <InputLabel align="left" htmlFor="dataInicial">
          Data Inicial:{" "}
        </InputLabel>
        <CustomDateTimePicker
          fullWidth
          variant="inline"
          iconcolor={colors.BLUE}
          format="DD/MM/YYYY HH:mm"
          name="dataInicial"
          value={filters.dataInicial}
          onChange={handleChangeFilters}
          KeyboardButtonProps={{
            "aria-label": "Mudar data",
          }}
        />
      </Grid>
      <Grid item xs={12} md={3}>
        <InputLabel align="left" htmlFor="dataFinal">
          Data Final:{" "}
        </InputLabel>
        <CustomDateTimePicker
          fullWidth
          variant="inline"
          iconcolor={colors.BLUE}
          format="DD/MM/YYYY HH:mm"
          name="dataFinal"
          value={filters.dataFinal}
          onChange={handleChangeFilters}
          KeyboardButtonProps={{
            "aria-label": "Mudar data",
          }}
        />
      </Grid>
      <Grid item xs={12} md={2}>
        <Button
          fullWidth
          variant="contained"
          style={{
            height: 40,
            backgroundColor: colors.BLUE,
            color: "white",
            marginTop: 14,
            boxShadow: "none"
          }}
          onClick={handleSubmit}
        >
          <FilterList /> Filtrar
        </Button>
      </Grid>

      {gestor && (
        <Grid item xs={12} md={12}>
          <Typography style={{ fontSize: 16, marginBottom: "15px" }} align="left">
            <label style={{marginLeft: "7px"}}>Selecionar intervalo pelos meses: </label><br/>

            {ultimas12Faturas()
              .reverse()
              .map((date, index) => {
                return (
                  <span key={index}>
                    <Button
                      style={{
                        textDecoration:
                        dateSelected === date ? "underline" : "none",
                        textDecorationColor: "green",
                      }}
                      size="small"
                      onClick={(event) => {
                        event.preventDefault();
                        handleChangeDateByLastMonth(date);
                        setDateSelected(date);
                      }}
                    >
                      {moment(date).format("MMMM")}
                    </Button>
                    {index < 11 && " | "}
                  </span>
                );
              })}
          </Typography>
        </Grid>
      )}

      <Grid
        container
        style={{
          backgroundColor: colors.WHITE,
          borderRadius: 8,
          margin: 10,
          color: colors.PRIMARY,
          border: `1px solid ${colors.LIGHT_GREY}`,
          align: "left",
          alignItems: "start",
          justifySelf: "start",
          padding: "0px 10px"
        }}
        spacing={3}
      >
        <Grid item xs={12} style={{ display: "flex" }}>
          <Receipt style={{ color: colors.BLUE, marginRight: 10 }} />
          <Typography
            align="left"
            style={{
              fontWeight: "bold",
              color: colors.PRIMARY,
            }}
          >
            Selecione os relatórios que deseja gerar
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <FormControlLabel
            label="Consumo"
            control={
              <Checkbox
                style={{ color: colors.BLUE }}
                color="default"
                name="mostrarAguaDetalhado"
                checked={filters.mostrarAguaDetalhado}
                edge="start"
                onChange={handleChangeFilters}
              />
            }
          />
        </Grid>
        <Grid item xs={12} sm={5} md={4}>
          <FormControlLabel
            label="Relatório Gerencial (Gráficos)"
            control={
              <Checkbox
                style={{ color: colors.BLUE }}
                color="default"
                name="mostrarAguaGerencial"
                checked={filters.mostrarAguaGerencial}
                edge="start"
                onChange={handleChangeFilters}
              />
            }
          />
        </Grid>
        {permission == 3 &&
          <Grid item xs={12} sm={6} md={4}>
            <FormControlLabel
              label="Dados Equipamento"
              control={
                <Checkbox
                  style={{ color: colors.BLUE }}
                  color="default"
                  name="mostrarRelatorioDados"
                  checked={filters.mostrarRelatorioDados}
                  edge="start"
                  onChange={handleChangeFilters}
                />
              }
            />
          </Grid>
        }
      </Grid>
    </Grid>
  );
}
