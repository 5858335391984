import React from "react";
import { Grid, Typography, Card, TextField } from "@material-ui/core";
import { createTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import moment from "moment";
import { formatMoney } from "../default/table/formatter";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import { colors } from "../constants/dc_constants";
import { Label } from "@material-ui/icons";
import { Input } from "@mui/material";
const styles = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
  },
  periodo: {
    border: "5px solid black",
    borderRadius: 10,
    padding: 4,
  },
  description: {
    border: "5px solid black",
    borderRadius: "10px",
    height: "5vw",
    padding: 4,
  },
  total: {
    marginTop: 42,
    borderRadius: 10,
    height: "5vw",
    backgroundColor: "black",
    textAlign: "left",
    padding: 10,
  },
  form: {
    minHeigth: 600,
    maxWidth: 775,
    minWidth: 775,
  },
  headTable: {
    backgroundColor: "black",
    borderCollapse: "collapse",
    padding: "none",
    margin: "none",
  },

  table: {
    colspan: "0",
    marginTop: 31,
    borderRadius: "10px",
    overflow: "hidden",
    fontSize: "14px",
    width: "100%",
  },

  rowTable: {
    backgroundColor: "write",
    borderCollapse: "separate",
    fontSize: "14px",
  },
});

const theme = createTheme({
  overrides: {
    MuiToggleButton: {
      root: {
        '&$selected': {
          backgroundColor: colors.ORANGE_POWER,
        },
      },
    },
  },
});

class FaturaEnergia extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fechamentoData: {
        kw_total: 0,
        kvar_total: 0,
        kw_ponta: 0,
        kw_fora_ponta: 0,
        kw_reservado: 0,
        kvar_ponta: 0,
        kvar_fora_ponta: 0,
        kvar_reservado: 0,
        kw_hp_max: 0,
        kw_hfp_max: 0,
        kw_hr_max: 0,
        ultrapassagem: 0,
        ufer_total: 0,
        uferP: 0,
        uferR: 0,
        uferFP: 0,
        kw_injetado_total: 0,
        kw_injetado_ponta: 0,
        kw_injetado_fora_ponta: 0,
        kw_injetado_max_ponta: 0,
        kw_injetado_max_fora_ponta: 0
      },
      
      amountValues: {
        tarifa_hp_total: 0,
        tarifa_hfp_total: 0,
        tarifa_hr_total: 0,
        tarifa_total_kw: 0,
        tarifa_hfp_kvar: 0,
        tarifa_hp_kvar: 0,
        tarifa_hr_kvar: 0,
        tarifa_total_kvar: 0,
        demanda_ativa: 0,
        demanda_reativa: 0,
        demanda_ativa_value: 0,
        demanda_reativa_value: 0,
        demanda_injetada: 0,
        porcentagemUferP: 0,
        porcentagemUferR: 0,
        porcentagemUferFP: 0,
        porcentagemUferTotal: 0,
        total: 0,
      },
      escolha: "1",
      porcentagem: 100,
      newDemanda: null,
      maxDemanda: -1,
    };
  }

  showNumberValue = (value) => {
    var setDecimal = {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    };
    if (value === null || value === undefined) return " -- ";
    else return parseFloat(value).toLocaleString("de-DE", setDecimal);
  };

  dateFormat = (date, dateOnly) => {
    if (date) {

      return dateOnly ? moment(date).format("DD/MM/YYYY") : moment(date).format("DD/MM/YYYY HH:mm");
    }
  };

  calcularPorcentagem(value) {
    return (value * 100) / this.state.maxDemanda
  }

  CalcularDemanda() {
    return Math.max(
      this.props.data.kw_hr_max,
      this.props.data.kw_hfp_max,
      this.props.data.kw_hp_max
    );
  }

  contractedDemandPercentage() {
    return (this.state.amountValues.demanda_ativa * 100) / this.props.gestor.selected.demanda_contratada;
  }

  arredondar(value) {
    return value == "" ? value : Math.round(value);
  }

  calculateValues = () => {
    if (this.props.gestor.selected && this.props.data) {
      let tarifa_hfp = 0,
        tarifa_hp = 0,
        tarifa_hr = 0,
        tarifa_hfp_kvar = 0,
        tarifa_hp_kvar = 0,
        tarifa_hr_kvar = 0,
        tarifa_total_kw = 0,
        tarifa_total_kvar = 0,
        tarifa_demanda_ativa = 0,
        tarifa_demanda_reativa = 0,
        tarifa_demanda_injetada = 0,
        porcentagemFP = 0,
        porcentagemP = 0,
        porcentagemR = 0,
        porcentagemT = 0;

      tarifa_hp =
        this.props.data.kw_ponta * this.props.gestor.selected.concessionaria.tarifa_hp;
      tarifa_hfp =
        this.props.data.kw_fora_ponta * this.props.gestor.selected.concessionaria.tarifa_hfp;
      tarifa_hr =
        this.props.data.kw_reservado * this.props.gestor.selected.concessionaria.tarifa_hr;

      //Consumo ativo
      tarifa_total_kw = tarifa_hp + tarifa_hfp + tarifa_hr;

      tarifa_hp_kvar =
        this.props.data.uferP *
        this.props.gestor.selected.concessionaria.tarifa_hfp_reativo;
      tarifa_hfp_kvar =
        this.props.data.uferFP *
        this.props.gestor.selected.concessionaria.tarifa_hfp_reativo;
      tarifa_hr_kvar =
        this.props.data.uferR *
        this.props.gestor.selected.concessionaria.tarifa_hfp_reativo;

      //porcentagem ufer
      porcentagemR = (this.props.data.kw_reservado == 0) ? 0 : ((this.props.data.uferR * 100) / this.props.data.kw_reservado).toFixed(1);
      porcentagemFP = (this.props.data.kw_fora_ponta == 0) ? 0 : ((this.props.data.uferFP * 100) / this.props.data.kw_fora_ponta).toFixed(1);
      porcentagemP = (this.props.data.kw_ponta == 0) ? 0 : ((this.props.data.uferP * 100) / this.props.data.kw_ponta).toFixed(1);
      porcentagemT = (this.props.data.kw_total == 0) ? 0 : ((this.props.data.ufer_total * 100) / this.props.data.kw_total).toFixed(1);

      //Consumo reativo
      tarifa_total_kvar = this.props.data.ufer_total * this.props.gestor.selected.concessionaria.tarifa_hfp_reativo;

      if (this.state.maxDemanda < 0) {
        this.state.maxDemanda = Math.max(
          this.props.data.kw_hr_max,
          this.props.data.kw_hfp_max,
          this.props.data.kw_hp_max)
        this.state.newDemanda = this.state.maxDemanda;
      };

      tarifa_demanda_injetada = Math.max(
        this.props.data.kw_injetado_max_ponta,
        this.props.data.kw_injetado_max_fora_ponta)

      tarifa_demanda_ativa = this.state.newDemanda;

      tarifa_demanda_reativa = Math.max(
        this.props.data.kvar_hr_max,
        this.props.data.kvar_hfp_max,
        this.props.data.kvar_hp_max
      );
      tarifa_demanda_reativa = tarifa_demanda_reativa * (this.state.porcentagem / 100);
      
      if (tarifa_demanda_ativa >= tarifa_demanda_reativa) {
        tarifa_demanda_reativa = 0;
      } else {
        tarifa_demanda_reativa = tarifa_demanda_reativa - tarifa_demanda_ativa;
      }
      if (tarifa_demanda_ativa > this.props.gestor.selected.demanda_contratada * 1.05) {
        this.state.fechamentoData.ultrapassagem = tarifa_demanda_ativa - this.props.gestor.selected.demanda_contratada;
      }

      let total = tarifa_demanda_ativa *
        this.props.gestor.selected.concessionaria.tarifa_demanda_ativa +
        tarifa_demanda_reativa *
        this.props.gestor.selected.concessionaria.tarifa_demanda_reativa +
        tarifa_total_kw +
        tarifa_total_kvar;

      if (this.props.gestor.selected.concessionaria.tarifa_ultrapassagem && this.state.fechamentoData.ultrapassagem > 0) {
        total += this.state.fechamentoData.ultrapassagem *
          this.props.gestor.selected.concessionaria.tarifa_ultrapassagem;
      }
      this.setState({
        amountValues: {
          tarifa_hp_total: tarifa_hp,
          tarifa_hfp_total: tarifa_hfp,
          tarifa_hr_total: tarifa_hr,
          tarifa_total_kw: tarifa_total_kw,

          tarifa_hfp_kvar: tarifa_hfp_kvar,
          tarifa_hp_kvar: tarifa_hp_kvar,
          tarifa_hr_kvar: tarifa_hr_kvar,
          tarifa_total_kvar: tarifa_total_kvar,

          demanda_ativa: tarifa_demanda_ativa,
          demanda_reativa: tarifa_demanda_reativa,
          demanda_injetada: tarifa_demanda_injetada,

          porcentagemUferFP: porcentagemFP,
          porcentagemUferP: porcentagemP,
          porcentagemUferR: porcentagemR,
          porcentagemUferTotal: porcentagemT,

          demanda_ativa_value:
            tarifa_demanda_ativa *
            this.props.gestor.selected.concessionaria.tarifa_demanda_ativa,
          demanda_reativa_value:
            tarifa_demanda_reativa *
            this.props.gestor.selected.concessionaria.tarifa_demanda_reativa,

          total: total,
        },
      });
    }
  };

  handleChangeEscolha = (value) => {
    //let value = (Number(this.state.escolha) * -1)+""; //Alterando de 1 para -1 ou o contrario 
    this.setState({ escolha: value }, () => {
      console.log(value)
    });
  }

  handleChangePorcentagem = (event) => {
    console.log(this.state.escolha);
    switch (this.state.escolha) {
      case "1":
        if (event.target.value <= 100 &&
          event.target.value >= 0) {
          let value = this.state.maxDemanda * (event.target.value / 100);
          this.setState({ porcentagem: this.arredondar(event.target.value), newDemanda: this.arredondar(value) }, () => {
            this.calculateValues();
          });
        }
        break;
      case "-1":
        console.log(event.target.value + " ==== " + this.state.maxDemanda);
        if (event.target.value <= this.state.maxDemanda &&
          event.target.value >= 0) {
          let value = this.calcularPorcentagem(event.target.value)
          this.setState({
            newDemanda: this.arredondar(event.target.value), porcentagem: this.arredondar(value)
          }, () => {
            this.calculateValues();
          });
        }
        break;
    }
  }


  componentWillMount() {
    this.calculateValues();
  }

  render() {
    const { classes } = this.props;

    return (
      <Grid container style={{ marginTop: 20, padding: 10 }} spacing={3}>
        <Grid container style={{ marginTop: 20 }} spacing={3}>
          <Grid align="left" item xs={5}>
            <img
              alt="logo"
              style={{ marginLeft: 1, marginTop: 13 }}
              width={"60%"}
              src="/static/images/logo.png"
            ></img>
          </Grid>
          <ThemeProvider theme={theme}>
            <Grid item xs={1} style={{ alignItems: "flex", justifyContent: "flex" }}>
              <ToggleButtonGroup
                color="primary"
                value={this.state.escolha}
                exclusive
                aria-label="Platform">
                <ToggleButton style={{ width: '50%' }}
                  onClick={() => this.handleChangeEscolha("1")}
                  value="1">%
                </ToggleButton>
                <ToggleButton style={{ width: '50%' }}
                  onClick={() => this.handleChangeEscolha("-1")}
                  value="-1">kW
                </ToggleButton>
              </ToggleButtonGroup>
            </Grid>
          </ThemeProvider>
          <Grid item xs={1} style={{ display: "flex", alignItems: "flex", justifyContent: "flex" }}>
            <TextField
              id="variavel"
              name="variavel"
              type="number"
              label="Demanda"
              value={this.state.escolha == "1" ? this.state.porcentagem : this.state.newDemanda}
              onChange={this.handleChangePorcentagem}
              InputProps={{ endAdornment: this.state.escolha == "1" ? "%" : "kW" }}
            />
          </Grid>
          <Grid item xs={5}>
            <Card className={classes.periodo}>
              <Typography variant="button" style={{ fontWeight: 700 }}>
                Período do relatório
              </Typography>
              <Typography>
                {this.dateFormat(this.props.dateStart)}
                {" à "}
                {this.dateFormat(this.props.dateEnd)}
              </Typography>
            </Card>
          </Grid>
        </Grid>
        <Grid container style={{ marginTop: 20 }} spacing={3}>
          <Grid align="left" item xs={8}>
            <Typography style={{ fontWeight: "bold", padding: 5 }} variant="h6">
              Simulação de Fatura
            </Typography>
            <Card className={classes.description}>
              <Typography style={{ fontWeight: "600" }}>
                {`Equipamento : ${this.props.gestor.selected.id_equipamento} `}
              </Typography>
              <Typography style={{ fontWeight: "600" }}>
                {`Localidade do equipamento : ${this.props.gestor.selected.fazenda} - ${this.props.gestor.selected.nome_bombeamento}`}
              </Typography>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Card className={classes.total}>
              <Typography style={{ fontWeight: 600, color: "white" }}>
                VALOR A PAGAR
              </Typography>
              <Typography style={{ color: "white" }}>
                {formatMoney(this.state.amountValues.total)} c/ impostos
                inclusos
              </Typography>
            </Card>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 20 }} spacing={3}>
          <Grid item xs={6}>
            <Table cellSpacing="0" cellPadding="0" className={classes.table}>
              <Thead className={classes.headTable}>
                <Tr>
                  <Th>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#f0f2f1",
                        marginBottom: 10,
                        padding: 8,
                      }}
                      align="left"
                    >
                      {" "}
                      Demanda Ativa Registrada
                    </Typography>
                  </Th>
                  <Th></Th>
                  <Th>
                    <Typography
                      style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
                      align="left"
                    >
                      Data: {this.dateFormat(this.props.data.data_coleta[0], true)}{" "}
                    </Typography>
                  </Th>
                </Tr>
              </Thead>
              <Tbody className={classes.rowTable}>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      KW:{" "}
                      {`${this.showNumberValue(
                        this.state.amountValues.demanda_ativa
                      )}`}
                      {" ("}{`${this.contractedDemandPercentage().toFixed()}`}{"%)"}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Unit.: R$
                      {`${this.showNumberValue(
                        this.props.gestor.selected.concessionaria.tarifa_demanda_ativa
                      )}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Total: R${" "}
                      {`${this.showNumberValue(
                        this.state.amountValues.demanda_ativa_value
                      )}`}{" "}
                    </Typography>
                  </Th>
                </Tr>

                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Ultrapassagem:{" "}
                      {`${this.showNumberValue(
                        this.state.fechamentoData.ultrapassagem
                      )}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Unit.: R$
                      {`${this.showNumberValue(
                        this.props.gestor.selected.concessionaria.tarifa_ultrapassagem
                      )}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Total: R${" "}
                      {`${this.showNumberValue(
                        (this.state.fechamentoData.ultrapassagem ?? 0) * (this.props.gestor.selected.concessionaria.tarifa_ultrapassagem ?? 0)
                      )}`}{" "}
                    </Typography>
                  </Th>
                </Tr>

                {this.props.gestor.selected.demanda_geracao &&
                  <Tr>
                    <Th>
                      <Typography
                        style={{ marginBottom: 0, padding: 8 }}
                        align="left"
                      >
                        Demanda Injetada:
                        {` ${this.showNumberValue(
                          this.state.amountValues.demanda_injetada
                        )}`}{" "}
                      </Typography>
                    </Th>
                  </Tr>
                }
              </Tbody>
            </Table>
          </Grid>

          <Grid item xs={6}>
            <Table cellSpacing="0" cellPadding="0" className={classes.table}>
              <Thead className={classes.headTable}>
                <Tr>
                  <Th>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#f0f2f1",
                        marginBottom: 10,
                        padding: 8,
                      }}
                      align="left"
                    >
                      {" "}
                      Demanda Reativa Excedente{" "}
                    </Typography>
                  </Th>
                  <Th></Th>
                  <Th>
                    <Typography
                      style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
                      align="left"
                    >
                      Data: {this.dateFormat(this.props.data.data_coleta[0], true)}{" "}
                    </Typography>
                  </Th>
                </Tr>
              </Thead>

              <Tbody className={classes.rowTable}>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      kVAr:{" "}
                      {`${this.showNumberValue(
                        this.state.amountValues.demanda_reativa
                      )}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Unit.: R${" "}
                      {`${this.showNumberValue(
                        this.props.gestor.selected.concessionaria.tarifa_demanda_reativa
                      )}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Total: R$
                      {`${this.showNumberValue(
                        this.state.amountValues.demanda_reativa_value
                      )}`}
                    </Typography>
                  </Th>
                </Tr>
              </Tbody>
            </Table>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 20 }} spacing={3}>
          <Grid item xs={12}>
            <Table cellSpacing="0" cellPadding="0" className={classes.table}>
              <Thead className={classes.headTable}>
                <Tr>
                  <Th>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#f0f2f1",
                        marginBottom: 10,
                        padding: 8,
                      }}
                      align="left"
                    >
                      {" "}
                      Consumo Ativo
                    </Typography>
                  </Th>
                  <Th>
                    {" "}
                    <Typography
                      style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
                      align="left"
                    >
                      KWH{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
                      align="left"
                    >
                      VALOR UNITÁRIO{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
                      align="left"
                    >
                      TOTAL{" "}
                    </Typography>
                  </Th>
                </Tr>
              </Thead>
              <Tbody className={classes.rowTable}>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Consumo registrado na ponta{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      {`${this.showNumberValue(this.props.data.kw_ponta)}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      R${" "}
                      {`${this.showNumberValue(
                        this.props.gestor.selected.concessionaria.tarifa_hp
                      )}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      R${" "}
                      {`${this.showNumberValue(
                        this.props.data.kw_ponta *
                        this.props.gestor.selected.concessionaria.tarifa_hp
                      )}`}
                    </Typography>
                  </Th>
                </Tr>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Consumo registrado fora de ponta{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {`${this.showNumberValue(this.props.data.kw_fora_ponta)}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      R${" "}
                      {`${this.showNumberValue(
                        this.props.gestor.selected.concessionaria.tarifa_hfp
                      )}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      R${" "}
                      {`${this.showNumberValue(
                        this.props.data.kw_fora_ponta *
                        this.props.gestor.selected.concessionaria.tarifa_hfp
                      )}`}{" "}
                    </Typography>
                  </Th>
                </Tr>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Consumo registrado reservado{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      {`${this.showNumberValue(
                        this.props.data.kw_reservado
                      )}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      R${" "}
                      {`${this.showNumberValue(
                        this.props.gestor.selected.concessionaria.tarifa_hr
                      )}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      R${" "}
                      {`${this.showNumberValue(
                        this.props.data.kw_reservado *
                        this.props.gestor.selected.concessionaria.tarifa_hr
                      )}`}{" "}
                    </Typography>
                  </Th>
                </Tr>
              </Tbody>
            </Table>
          </Grid>
          {this.props.gestor.selected.demanda_geracao &&
            <Grid item xs={12}>
              <Table cellSpacing="0" cellPadding="0" className={classes.table}>
                <Thead className={classes.headTable}>
                  <Tr>
                    <Th>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#f0f2f1",
                          marginBottom: 10,
                          padding: 8,
                        }}
                        align="left"
                      >
                        {" "}
                        Consumo Injetado Detalhado
                      </Typography>
                    </Th>
                    <Th>
                      {" "}
                      <Typography
                        style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
                        align="right"
                      >
                        KWH{" "}
                      </Typography>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody className={classes.rowTable}>
                  <Tr>
                    <Th>
                      <Typography
                        style={{ marginBottom: 0, padding: 8 }}
                        align="left"
                      >
                        Consumo registrado na ponta{" "}
                      </Typography>
                    </Th>
                    <Th>
                      <Typography
                        style={{ marginBottom: 0, padding: 8 }}
                        align="right"
                      >
                        {" "}
                        {`${this.showNumberValue(this.props.data.kw_injetado_ponta)}`}{" "}
                      </Typography>
                    </Th>
                  </Tr>
                  <Tr>
                    <Th>
                      <Typography
                        style={{ marginBottom: 0, padding: 8 }}
                        align="left"
                      >
                        Consumo registrado fora de ponta{" "}
                      </Typography>
                    </Th>

                    <Th>
                      <Typography
                        style={{ marginBottom: 0, padding: 8 }}
                        align="right"
                      >
                        {" "}
                        {`${this.showNumberValue(this.props.data.kw_injetado_fora_ponta)}`}{" "}
                      </Typography>
                    </Th>
                  </Tr>
                </Tbody>
              </Table>
            </Grid>
          }
        </Grid>

        <Grid container style={{ marginTop: 20 }} spacing={3}>
          <Grid item xs={6}>
            <Table cellSpacing="0" cellPadding="0" className={classes.table}>
              <Thead className={classes.headTable}>
                <Tr>
                  <Th>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#f0f2f1",
                        marginBottom: 10,
                        padding: 8,
                      }}
                      align="left"
                    >
                      {" "}
                      UFER
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
                      align="left"
                    >
                      KVARh{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
                      align="left"
                    >
                      Porcentagem{" "}
                    </Typography>
                  </Th>
                  <Th>
                    {" "}
                    <Typography
                      style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
                      align="left"
                    >
                      VALOR UNITÁRIO{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
                      align="left"
                    >
                      TOTAL{" "}
                    </Typography>
                  </Th>
                </Tr>
              </Thead>
              <Tbody className={classes.rowTable}>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      HP{" "}
                    </Typography>
                  </Th>

                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {`${this.showNumberValue(this.props.data.uferP)}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {`${this.state.amountValues.porcentagemUferP}%`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      R${" "}
                      {`${this.showNumberValue(
                        this.props.gestor.selected.concessionaria.tarifa_hfp_reativo
                      )}`}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      R${" "}
                      {`${this.showNumberValue(
                        this.state.amountValues.tarifa_hp_kvar
                      )}`}{" "}
                    </Typography>
                  </Th>
                </Tr>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      HFP{" "}
                    </Typography>
                  </Th>

                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      {`${this.showNumberValue(
                        this.props.data.uferFP
                      )}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {`${this.state.amountValues.porcentagemUferFP}%`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      R${" "}
                      {`${this.showNumberValue(
                        this.props.gestor.selected.concessionaria.tarifa_hfp_reativo
                      )}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      R${" "}
                      {`${this.showNumberValue(
                        this.state.amountValues.tarifa_hfp_kvar
                      )}`}{" "}
                    </Typography>
                  </Th>
                </Tr>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      HR{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      {`${this.showNumberValue(
                        this.props.data.uferR
                      )}`}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {`${this.state.amountValues.porcentagemUferR}%`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      R${" "}
                      {`${this.showNumberValue(
                        this.props.gestor.selected.concessionaria.tarifa_hfp_reativo
                      )}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      R${" "}
                      {`${this.showNumberValue(
                        this.state.amountValues.tarifa_hr_kvar
                      )}`}
                    </Typography>
                  </Th>
                </Tr>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Total{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      {`${this.showNumberValue(
                        this.props.data.ufer_total
                      )}`}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {`${this.state.amountValues.porcentagemUferTotal}%`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      R${" "}
                      {`${this.showNumberValue(
                        this.props.gestor.selected.concessionaria.tarifa_hfp_reativo
                      )}`}{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      R${" "}
                      {`${this.showNumberValue(
                        this.state.amountValues.tarifa_total_kvar
                      )}`}
                    </Typography>
                  </Th>
                </Tr>
              </Tbody>
            </Table>
          </Grid>

          <Grid item xs={6}>
            <Table cellSpacing="0" cellPadding="0" className={classes.table}>
              <Thead className={classes.headTable}>
                <Tr>
                  <Th>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#f0f2f1",
                        marginBottom: 10,
                        padding: 8,
                      }}
                      align="left"
                    >
                      {" "}
                      Consumo Reativo Detalhado
                    </Typography>
                  </Th>
                  <Th>
                    {" "}
                    <Typography
                      style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
                      align="right"
                    >
                      KVARh{" "}
                    </Typography>
                  </Th>
                </Tr>
              </Thead>
              <Tbody className={classes.rowTable}>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Consumo registrado na ponta{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="right"
                    >
                      {`${this.showNumberValue(this.props.data.kvar_ponta)}`}{" "}
                    </Typography>
                  </Th>
                </Tr>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Consumo registrado fora de ponta{" "}
                    </Typography>
                  </Th>

                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="right"
                    >
                      {" "}
                      {`${this.showNumberValue(
                        this.props.data.kvar_fora_ponta
                      )}`}{" "}
                    </Typography>
                  </Th>
                </Tr>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Consumo registrado reservado{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="right"
                    >
                      {" "}
                      {`${this.showNumberValue(
                        this.props.data.kvar_reservado
                      )}`}
                    </Typography>
                  </Th>
                </Tr>
              </Tbody>
            </Table>
          </Grid>
        </Grid>

        <Grid container style={{ marginTop: 20 }} spacing={3}>
          <Grid item xs={true}>
            <Table cellSpacing="0" cellPadding="0" className={classes.table}>
              <Thead className={classes.headTable}>
                <Tr>
                  <Th>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#f0f2f1",
                        marginBottom: 10,
                        padding: 8,
                      }}
                      align="left"
                    >
                      {" "}
                      DETALHAMENTO DEMANDA ATIVA
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
                      align="left"
                    >
                      KW{" "}
                    </Typography>
                  </Th>
                </Tr>
              </Thead>
              <Tbody className={classes.rowTable}>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Demanda registrada na ponta{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      {`${this.showNumberValue(
                        this.props.data.kw_hp_max
                      )}`}{" "}
                    </Typography>
                  </Th>
                </Tr>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Demanda registrada fora de ponta{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {`${this.showNumberValue(this.props.data.kw_hfp_max)}`}{" "}
                    </Typography>
                  </Th>
                </Tr>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Demanda registrada reservado
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {`${this.showNumberValue(this.props.data.kw_hr_max)}`}{" "}
                    </Typography>
                  </Th>
                </Tr>
              </Tbody>
            </Table>
          </Grid>

          <Grid item xs={true}>
            <Table cellSpacing="0" cellPadding="0" className={classes.table}>
              <Thead className={classes.headTable}>
                <Tr>
                  <Th>
                    <Typography
                      style={{
                        fontWeight: "bold",
                        color: "#f0f2f1",
                        marginBottom: 10,
                        padding: 8,
                      }}
                      align="left"
                    >
                      {" "}
                      DETALHAMENTO DEMANDA REATIVA
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
                      align="left"
                    >
                      KWH{" "}
                    </Typography>
                  </Th>
                </Tr>
              </Thead>
              <Tbody className={classes.rowTable}>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Demanda registrada na ponta{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      {`${this.showNumberValue(
                        this.props.data.kvar_hp_max
                      )}`}{" "}
                    </Typography>
                  </Th>
                </Tr>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Demanda registrada fora de ponta{" "}
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {" "}
                      {`${this.showNumberValue(
                        this.props.data.kvar_hfp_max
                      )}`}{" "}
                    </Typography>
                  </Th>
                </Tr>
                <Tr>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      Demanda registrada reservado
                    </Typography>
                  </Th>
                  <Th>
                    <Typography
                      style={{ marginBottom: 0, padding: 8 }}
                      align="left"
                    >
                      {`${this.showNumberValue(this.props.data.kvar_hr_max)}`}{" "}
                    </Typography>
                  </Th>
                </Tr>
              </Tbody>
            </Table>
          </Grid>

          {this.props.gestor.selected.demanda_geracao &&
            <Grid item xs={true}>
              <Table cellSpacing="0" cellPadding="0" className={classes.table}>
                <Thead className={classes.headTable}>
                  <Tr>
                    <Th>
                      <Typography
                        style={{
                          fontWeight: "bold",
                          color: "#f0f2f1",
                          marginBottom: 10,
                          padding: 8,
                        }}
                        align="left"
                      >
                        {" "}
                        DETALHAMENTO DEMANDA INJETADA
                      </Typography>
                    </Th>
                    <Th>
                      <Typography
                        style={{ color: "#f0f2f1", marginBottom: 10, padding: 8 }}
                        align="left"
                      >
                        KW{" "}
                      </Typography>
                    </Th>
                  </Tr>
                </Thead>
                <Tbody className={classes.rowTable}>
                  <Tr>
                    <Th>
                      <Typography
                        style={{ marginBottom: 0, padding: 8 }}
                        align="left"
                      >
                        Demanda registrada na ponta{" "}
                      </Typography>
                    </Th>
                    <Th>
                      <Typography
                        style={{ marginBottom: 0, padding: 8 }}
                        align="left"
                      >
                        {`${this.showNumberValue(
                          this.props.data.kw_injetado_max_ponta
                        )}`}{" "}
                      </Typography>
                    </Th>
                  </Tr>
                  <Tr>
                    <Th>
                      <Typography
                        style={{ marginBottom: 0, padding: 8 }}
                        align="left"
                      >
                        Demanda registrada fora de ponta{" "}
                      </Typography>
                    </Th>
                    <Th>
                      <Typography
                        style={{ marginBottom: 0, padding: 8 }}
                        align="left"
                      >
                        {`${this.showNumberValue(
                          this.props.data.kw_injetado_max_fora_ponta
                        )}`}{" "}
                      </Typography>
                    </Th>
                  </Tr>
                </Tbody>
              </Table>
            </Grid>
          }

        </Grid>
        {this.props.permission == 3 &&
          <Grid xl={12} container
            style={
              {
                marginTop: 20,
                padding: '0px 40px 20px 20px'
              }
            } spacing={3}>
            <TextField
              variant="standard"
              fullWidth
              label="Análise técnica"
              multiline
              maxRows={1}
            />
          </Grid>
        }
      </Grid>
    );
  }
}
export default withStyles(styles)(FaturaEnergia);
