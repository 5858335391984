import moment from "moment";
import React, {
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

import {
  CloudDownloadRounded,
} from "@material-ui/icons";

import { gestorService } from "../../services/gestor_service";
import { formatMoney } from "../../default/table/formatter";
import FaturaEnergia from "../../_medidor/FaturaEnergia";
import { buildResumoFechamento } from "../../util/gestor_utils";
import RelatorioFiltros from "./filtros";
import LineChart from "../../components/LineChart";
import { coletaService } from "../../services/coleta_service";
import { Button, Grid, LinearProgress, Typography, Switch, FormControlLabel, NativeSelect, MenuItem } from "@material-ui/core";
import { calculaEconomiaDiaria } from "../_economia/relatorioEconomia";
import ObjectTable from "../../default/table/ObjectTable";
import { AttachMoney, PictureAsPdf } from "@material-ui/icons";
import { colors } from "../../constants/dc_constants";
import {
  headRowsEconomia,
  headRowsRelatorio,
  headRowsRelatorioMassa,
  headRowsRelatorioDados,
  headRowsRelatorioQueda
} from "../../constants/headRows";
import FechamentoView from "../_fechamento/FechamentoView";
import {
  closeProgressDialog,
  makeProgressDialog,
} from "../../default/dialogs/DialogRefInterface";
import {
  htmlToPng,
  template_completo,
} from "../../default/templates_pdf/template_relatorio";
import { convertTime, convertTimeEneel, makeDaily, sumDaily } from "../../util/relatorio_utils";
import { toast } from "react-toastify";
import { Select, InputLabel, FormControl } from "@mui/material";

function Relatorios(selected) {
  const RELATORIO_FATURA_ID = "relatorio_fatura_id";
  const RELATORIO_GERENCIAL_ID = "relatorio_gerencial_id";
  const RELATORIO_ECONOMIA_ID = "relatorio_economia_id";
  const RELATORIO_MASSA_ID = "relatorio_massa_id";
  const RELATORIO_DADOS_ID = "relatorio_dados_id";
  const RELATORIO_ANEEL_ID = "relatorio_aneel_id";
  const RELATORIO_FECHAMENTO_ID = "relatorio_fechamento_id";
  const RELATORIO_QUEDA_ID = "relatorio_queda_id";
  const RELATORIO_DEMANDA_ANUAL_ID = "relatorio_demanda_anual_id";

  const LOADING_FATURA = "Carregando fatura...";
  const LOADING_DADOS = "Carregando dados...";
  const LOADING_ANEEL = "Carregando dados do relatório padrão ANEEL...";
  const LOADING_FECHAMENTO = "Carregando dados de fechamento...";

  const [filters, setFilters] = useState({
    dataInicial: moment().subtract(1, "month").format("YYYY-MM-DD 00:00"),
    dataFinal: moment().format("YYYY-MM-DD 23:59"),
    gestorId: selected?.id ?? null,
    permission: null,

    mostrarRelatorioFatura: false,
    mostrarRelatorioGerencial: false,
    mostrarRelatorioEconomia: false,
    mostrarRelatorioMassa: false,
    mostrarRelatorioAneel: false,
    mostrarRelatorioFechamento: false,
    mostrarRelatorioDados: false,
    mostrarRelatorioQueda: false,
    mostrarRelatorioDemandaAnual: false,
  });

  const [dadosRelatorioFatura, setDadosRelatorioFatura] = useState({});
  const [dadosColetas, setDadosColetas] = useState([]);
  const [dadosRelatorioEconomia, setDadosRelatorioEconomia] = useState([]);
  const [dadosRelatorioAneel, setDadosRelatorioAneel] = useState([]);
  const [dadosRelatorioFechamento, setDadosRelatorioFechamento] = useState({});
  const [dadosRelatorioQueda, setDadosRelatorioQueda] = useState([]);
  const [dadosProdist, setDadosProdist] = useState([]);
  const [dadosMassa, setDadosMassa] = useState([]);
  const [dadosDemandaAnual, setDadosDemandaAnual] = useState([]);

  const [gestores, setGestores] = useState([]);

  const [gestorSelecionado, setGestorSelecionado] = useState(null);

  const [loadings, setLoadings] = useState([]);

  const correnteChartRef = useRef(null);
  const potenciaChartRef = useRef(null);
  const consumoChartRef = useRef(null);
  const tensaoChartRef = useRef(null);
  const demandaAnualChartRef = useRef(null);

  const [maiorQueda, setMaiorQueda] = useState();
  const [tempoTotalOff, setTempoTotalOff] = useState();

  const [correnteChartPng, setCorrenteChartPng] = useState(null);
  const [potenciaChartPng, setPotenciaChartPng] = useState(null);
  const [tensaoChartPng, setTensaoChartPng] = useState(null);
  const [consumoChartPng, setConsumoChartPng] = useState(null);
  const [demandaAnualChartPng, setDemandaAnualChartPng] = useState(null);

  const [prodist, setProdist] = useState(false);
  const [listYears, setListYears] = useState([]);
  const [year, setYear] = useState(moment().year());

  const updateChartsByRef = () => {
    if (correnteChartRef?.current) {
      correnteChartRef.current.updateSelecteds();
    }
    if (potenciaChartRef?.current) {
      potenciaChartRef.current.updateSelecteds();
    }
    if (consumoChartRef?.current) {
      consumoChartRef.current.updateSelecteds();
    }
    if (tensaoChartRef?.current) {
      tensaoChartRef.current.updateSelecteds();
    }
    if (demandaAnualChartRef?.current) {
      demandaAnualChartRef.current.updateSelecteds();
    }
  };

  const loadRelatorioFatura = useCallback(() => {
    try {
      setLoadings([...loadings, LOADING_FATURA]);
      const { dataInicial, dataFinal, gestorId } = filters;
      gestorService.getResumoDados(
        { dataInicial, dataFinal, gestorId },
        (response) => {
          if (response.status === 200) {
            const { data } = response;
            const resumoFechamento = buildResumoFechamento(data);
            setDadosRelatorioFatura(resumoFechamento);
          }

          setTimeout(() => {
            setLoadings(loadings.filter((id) => id !== LOADING_FATURA));
          }, 500);
        }
      );
    } catch (error) {
      console.log(error);
      setLoadings((loadings) =>
        loadings.filter((loading) => loading !== LOADING_FATURA)
      );
    }
  }, [filters, setDadosRelatorioFatura, setLoadings, loadings]);

  function makeProdist(data) {
    setDadosMassa(data);
    setDadosProdist(data.filter(obj => moment(obj.data_coleta).minutes() % 10 === 0));
  }

  function loadListYears(gestor) {
    const createdYear = moment(gestor.createdAt).year();
    let year = moment().year();
    let years = [];
    while (year >= createdYear) {
      years = years.concat(year);
      year--;
    }
    return years;
  }

  function changeYear(event) {
    setYear(event.target.value);
    loadDadosAnual(event.target.value);
  }

  function findOutages(data) { //encontrar quedas
    let outages = [];
    let totalTime = [0, 0];
    let wasWorking = true;
    let offMoment = null;
    let onMoment = null;
    let duracao = null;
    let maiorDuracao = 0;

    data.forEach(coleta => {
      if (coleta.status_energia == 0 && wasWorking) {
        offMoment = moment(coleta.data_coleta);
        wasWorking = false;
      }
      else if (coleta.status_energia == 0 && !wasWorking) {
        onMoment = moment(coleta.data_coleta);
      }
      else if (coleta.status_energia == 1 && !wasWorking) {
        onMoment = onMoment || offMoment;
        duracao = convertTime(onMoment.diff(offMoment, 'minutes'));
        let duracaoString = (duracao.hours ? `${duracao.hours} hora(s) e ` : ``) + `${duracao.minutes} minuto(s)`;
        outages.push({ offMoment, onMoment, duracaoString });
        totalTime[0] += duracao.hours;
        totalTime[1] += duracao.minutes;
        duracao = +convertTimeEneel(duracao.hours, duracao.minutes);
        if (duracao > maiorDuracao) maiorDuracao = duracao;
        wasWorking = true;
        offMoment = null;
        onMoment = null;
      }
    });
    if (offMoment != null) {
      onMoment = "Sem retorno";
      outages.push({ offMoment, onMoment });
      toast.warn(`Houve queda dia ${moment(offMoment).format('DD/MM - HH:mm')}, sem retorno no periodo selecionado`, {
        autoClose: false,
        theme: "dark",
      })
    }
    duracao = convertTime(totalTime[1]);
    totalTime[0] += duracao.hours;
    totalTime[1] = duracao.minutes;
    duracao = convertTimeEneel(totalTime[0], totalTime[1]);

    setMaiorQueda(maiorDuracao);
    setDadosRelatorioQueda(outages);
    setTempoTotalOff(duracao);
  }

  const loadDadosColetas = useCallback(() => {
    try {
      setLoadings([...loadings, LOADING_DADOS]);
      const queryData = {
        gestor_id: filters.gestorId,
        data_inicial: moment(filters.dataInicial).format("YYYY-MM-DDTHH:mm:ss"),
        data_final: moment(filters.dataFinal).format("YYYY-MM-DDTHH:mm:ss"),
        attr: [
          "id",
          "data_coleta",
          "energia_consumida_kw_acc",
          "energia_consumida_kwar_acc",
          "potencia_ativa_total",
          "potencia_reativa_total",
          "potencia_aparente_total",
          "energia_consumida_kvarh",
          "energia_consumida_kwh",
          "tensao_l1_l2",
          "tensao_l2_l3",
          "tensao_l3_l1",
          "tensao_l1_neutro",
          "tensao_l2_neutro",
          "tensao_l3_neutro",
          "corrente_i1",
          "corrente_i2",
          "corrente_i3",
          "corrente_n",
          "frequencia",
          "fator_potencia_total",
          "posto_horario",
          "posto_reativo",
          "quadrante",
          "tarifa_reativos_ativada",
          "index_abs",
          "status_energia",
          "modo_ssu",
          "alarme",
          "createdAt"
        ],
      };
      coletaService.getColetas(queryData, (response) => {
        if (response.status === 200) {
          const { data } = response;
          setDadosColetas(data);

          updateChartsByRef();

          filters.mostrarRelatorioMassa && makeProdist(data);
          filters.mostrarRelatorioQueda && findOutages(data);

          setTimeout(() => {
            setLoadings((loadings) =>
              loadings.filter((loading) => loading !== LOADING_DADOS)
            );
          }, 2000);
        }
      });
    } catch (error) {
      setLoadings((loadings) =>
        loadings.filter((loading) => loading !== LOADING_DADOS)
      );
    }
  }, [filters, setDadosColetas, setLoadings, loadings]);

  const loadDadosAnual = useCallback((year) => {
    try {
      setLoadings([...loadings, LOADING_DADOS]);
      const gestor = gestores.find((gestor) => gestor.id === filters.gestorId)
      setListYears(loadListYears(gestor));
      const queryData = {
        gestor_id: filters.gestorId,
        year: year || moment().year(),
      };
      coletaService.getDemandaMensal(queryData, (response) => {
        if (response.status === 200) {
          const { data } = response;
          setDadosDemandaAnual(data);

          updateChartsByRef();

          setTimeout(() => {
            setLoadings((loadings) =>
              loadings.filter((loading) => loading !== LOADING_DADOS)
            );
          }, 2000);
        }
      });
    } catch (error) {
      setLoadings((loadings) =>
        loadings.filter((loading) => loading !== LOADING_DADOS)
      );
    }
  }, [filters, setDadosDemandaAnual, setLoadings, loadings]);

  const loadRelatorioAneelAndEconomia = useCallback(() => {
    try {
      setLoadings([...loadings, LOADING_ANEEL]);
      const { dataInicial, dataFinal, gestorId } = filters;
      const queryData = { dataInicial, dataFinal, gestorId };
      gestorService.getRelatorioAneel(queryData, (response) => {
        if (response.status === 200) {
          const { data } = response;
          if (filters.mostrarRelatorioAneel) {
            setDadosRelatorioAneel(data);
          }
          if (filters.mostrarRelatorioEconomia) {
            const gestor = gestores.find((gestor) => gestor.id === filters.gestorId);
            const relEconomia = calculaEconomiaDiaria(
              gestor,
              data,
              dataInicial,
              dataFinal
            );
            if (relEconomia <= 0) {
              toast.warn("Nenhum potencial de economia durante o período selecionado.",
                { autoClose: false }
              )
            }
            setDadosRelatorioEconomia(relEconomia);
          }

          setTimeout(() => {
            setLoadings((loadings) =>
              loadings.filter((loading) => loading !== LOADING_ANEEL)
            );
          }, 2000);
        }
      });
    } catch (error) {
      console.log(error);
      setLoadings((loadings) =>
        loadings.filter((loading) => loading !== LOADING_ANEEL)
      );
    }
  }, [filters, setLoadings, loadings]);

  const loadRelatorioFechamento = useCallback(() => {
    try {
      setLoadings([...loadings, LOADING_FECHAMENTO]);
      const { dataInicial, dataFinal, gestorId } = filters;
      const queryData = { dataInicial, dataFinal, gestorId };

      gestorService.getResumoDados(queryData, (response) => {
        if (response.status === 200) {
          const { data } = response;
          const resumoData = buildResumoFechamento(data);
          setDadosRelatorioFechamento(resumoData);
        }

        setTimeout(() => {
          setLoadings((loadings) =>
            loadings.filter((loading) => loading !== LOADING_FECHAMENTO)
          );
        }, 2000);
      });
    } catch (error) { }
  }, [filters, setDadosRelatorioFechamento, setLoadings, loadings]);

  const loadGestores = async () => {
    gestorService.getGestores("power", {}, (response) => {
      if (response.status === 200) {
        setGestores(response.data);
      }
    });
  };


  //Metodo usado para imprimir o relatorio.
  const printRelatorio = useCallback(async () => {
    try {
      makeProgressDialog(null, "Gerando relatório...").current.open();
      const dadosMassaRelatorio = {
        show: filters.mostrarRelatorioMassa,
        data: dadosMassa,
        headers: headRowsRelatorioMassa,
        title: `Relatório Dados de Massa${prodist ? " (Prodist modo 8)" : ""}`,
      };
      const dadosAneel = {
        show: filters.mostrarRelatorioAneel,
        data: dadosRelatorioAneel,
        headers: headRowsRelatorio,
        title: "Relatório Padrão Aneel",
      };
      const dadosEconomia = {
        show: filters.mostrarRelatorioEconomia,
        data: dadosRelatorioEconomia,
        headers: headRowsEconomia,
        title: "Potencial de Economia Diária",
      };

      const dadosEquipamento = {
        show: filters.mostrarRelatorioDados,
        data: dadosColetas,
        headers: headRowsRelatorioDados,
        title: "Relatório Dados do Equipamento",
      };

      const dadosQueda = {
        show: filters.mostrarRelatorioQueda,
        data: dadosRelatorioQueda,
        total: tempoTotalOff,
        maior: maiorQueda,
        headers: headRowsRelatorioQueda,
        title: "Relatório de quedas",
      }

      let divRelatorioFaturaPng, divRelatorioFechamentoPng;

      if (filters.mostrarRelatorioFatura) {
        nome: "-Fatura";
        divRelatorioFaturaPng = await htmlToPng(
          document.getElementById(RELATORIO_FATURA_ID)
        );
      }

      if (filters.mostrarRelatorioFechamento) {
        divRelatorioFechamentoPng = await htmlToPng(
          document.getElementById(RELATORIO_FECHAMENTO_ID)
        );
      }

      let somenteFechamento = false;

      const doc = template_completo({
        divRelatorioFaturaPng,
        divRelatorioFechamentoPng,
        correnteChartPng,
        potenciaChartPng,
        tensaoChartPng,
        demandaAnualChartPng,
        dadosMassaRelatorio,
        dadosAneel,
        dadosEconomia,
        dadosEquipamento,
        dadosQueda,
        somenteFechamento,
        consumoChartPng,
        headerParams: {
          orientation: "p",
          title: " ",
          data_hora: moment().format("DD/MM/YYYY - HH:mm:ss"),
          equipamento: gestorSelecionado?.nome || "",
          local: gestorSelecionado?.fazenda || "",
          regiao: gestorSelecionado?.regiao || "",
          data_inicio: moment(filters.dataInicial).format(
            "DD/MM/YYYY - HH:mm:ss"
          ),
          data_final: moment(filters.dataFinal).format("DD/MM/YYYY - HH:mm:ss"),
          year: year,
        },
      });

      //função que retorna o nome do arquivo
      const getReportName = () => {
        var nomeArquivo = gestorSelecionado?.fazenda.toLowerCase();
        nomeArquivo = nomeArquivo.normalize("NFD").replace(/[\u0300-\u036f]/g, "").replace(/\s+/g, ""); //remove acentuação e espaços.

        //Lista com todos os metodos
        var filterList = [{
          selected: filters.mostrarRelatorioAneel, text: "aneel"
        },
        {
          selected: filters.mostrarRelatorioEconomia, text: "economia"
        },
        {
          selected: filters.mostrarRelatorioFatura, text: "fatura"
        },
        {
          selected: filters.mostrarRelatorioFechamento, text: "rateio"
        },
        {
          selected: filters.mostrarRelatorioGerencial, text: "grafico"
        },
        {
          selected: filters.mostrarRelatorioMassa, text: "massa"
        },
        {
          selected: filters.mostrarRelatorioQueda, text: "queda"
        },
        {
          selected: filters.mostrarRelatorioDemandaAnual, text: "demanda"
        }
        ];

        filterList.map((filter) =>
          nomeArquivo += filter.selected ? (nomeArquivo.length > 0 ? `_${filter.text}` : filter.text) : ""
        )

        return nomeArquivo;
      }

      //os condicionais acima incrementam o nome do arquivo com os tipos de relatorios que foram gerados.

      //Metodo que imprime o relatorio. Valor entre parenteses refere-se ao nome que esse arquivo deve ter.
      doc.save(getReportName());

      closeProgressDialog();
    } catch (error) {
      console.log(error);
      closeProgressDialog();
    }
  }, [
    correnteChartPng,
    potenciaChartPng,
    tensaoChartPng,
    consumoChartPng,
    demandaAnualChartPng,
    filters,
    gestorSelecionado,
    dadosColetas,
    dadosRelatorioAneel,
    dadosRelatorioEconomia,
    dadosRelatorioQueda,
    dadosMassa,
    tempoTotalOff,
  ]);

  const onSubmit = (filters) => {
    setCorrenteChartPng(null);
    setPotenciaChartPng(null);
    setTensaoChartPng(null);
    setConsumoChartPng(null);
    setDemandaAnualChartPng(null);
    setFilters(filters);
    // if(gestorSelecionado && filters.mostrarRelatorioDemandaAnual) loadListYears
  };

  const onChangeFilters = (filters) => {
    setDadosColetas([]);
    setDadosRelatorioAneel([]);
    setDadosRelatorioEconomia([]);
    setDadosRelatorioFatura({});
    setDadosRelatorioFechamento({});
    setDadosRelatorioQueda([]);
    setDadosMassa([]);
    setDadosDemandaAnual([]);
  };

  useEffect(() => {
    if (filters.mostrarRelatorioAneel || filters.mostrarRelatorioEconomia) {
      loadRelatorioAneelAndEconomia();
    }
    if (filters.mostrarRelatorioFechamento) {
      loadRelatorioFechamento();
    }
    if (filters.mostrarRelatorioFatura) {
      loadRelatorioFatura();
    }
    if (filters.mostrarRelatorioDemandaAnual) {
      loadDadosAnual();
    }
    if (filters.mostrarRelatorioGerencial || filters.mostrarRelatorioMassa ||
      filters.mostrarRelatorioDados || filters.mostrarRelatorioQueda) {
      loadDadosColetas();
    }
  }, [filters]);

  useEffect(() => {
    let gestor = gestores.find((gestor) => gestor.id === filters.gestorId);
    if (gestor == undefined && selected) gestor = selected;
    setGestorSelecionado(gestor);
  }, [filters.gestorId]);

  useEffect(() => {
    loadGestores();
  }, []);

  useEffect(() => {
    if (loadings.length > 0) {
      makeProgressDialog(null, loadings[loadings.length - 1]).current.open();
    } else {
      closeProgressDialog();
    }
  }, [loadings]);

  useEffect(() => {
    prodist ? setDadosMassa(dadosProdist) : setDadosMassa(dadosColetas);
  }, [prodist]);

  const RelatorioFaturaMemo = useMemo(() => {
    return filters.mostrarRelatorioFatura &&
      Object.keys(dadosRelatorioFatura).length > 0 ? (
      <div id={RELATORIO_FATURA_ID} style={{ marginTop: 10 }}>
        <FaturaEnergia
          gestor={{ selected: gestorSelecionado }}
          data={dadosRelatorioFatura}
          dateEnd={filters.dataFinal}
          dateStart={filters.dataInicial}
          permission={filters.permission}
        />
      </div>
    ) : (
      <></>
    );
  }, [dadosRelatorioFatura, filters.mostrarRelatorioFatura]);

  const RelatorioGerencialMemo = useMemo(() => {
    return filters.mostrarRelatorioGerencial && dadosColetas.length > 0 ? (
      <Grid
        id={RELATORIO_GERENCIAL_ID}
        container
        spacing={3}
        style={{ marginTop: 30 }}
      >
        <Grid item xs={12}>
          {" "}
          <Typography style={{ fontSize: 20, fontWeight: "bold" }} align="left">
            Relatório Gerencial (Gráficos)
          </Typography>{" "}
        </Grid>

        {gestorSelecionado.equipamento == "ABB" && (
          <Grid item xs={12}>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }} align="left">
              Tensão (V)
            </Typography>{""}
            <Grid item xs={12}>
              <LineChart
                ref={tensaoChartRef}
                chartType={"LineChart"}
                data={dadosColetas}
                selected={[gestorSelecionado]}
                chartSize={400}
                chartSelecteds={["tensao_tensao"]}
                filter={false}
                onChartReady={(chart) => {
                  setTensaoChartPng(chart.getImageURI());
                }}
              />
            </Grid>
          </Grid>
        )}

        {gestorSelecionado.equipamento == "ABB" && (
          <Grid item xs={12}>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }} align="left">
              Corrente (A)
            </Typography>{" "}
            <Grid item xs={12}>
              <LineChart
                ref={correnteChartRef}
                chartType={"LineChart"}
                data={dadosColetas}
                selected={[gestorSelecionado]}
                chartSize={400}
                chartSelecteds={["corrente"]}
                filter={false}
                onChartReady={(chart) => {
                  setCorrenteChartPng(chart.getImageURI());
                }}
              />
            </Grid>
          </Grid>
        )}

        {gestorSelecionado.equipamento != "ABB" && (
          <Grid item xs={12}>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }} align="left">
              Consumo
            </Typography>{" "}
            <Grid item xs={12}>
              <LineChart
                ref={consumoChartRef}
                chartType={"LineChart"}
                data={sumDaily(dadosColetas)}
                selected={[gestorSelecionado]}
                chartSize={400}
                chartSelecteds={["consumoD"]}
                filter={false}
                ignoreHours={true}
                isStacked={true}
                canBeStackedAndNormal={true}
                slantedText={false}
                isGroupped={true}
                onChartReady={(chart) => {
                  setConsumoChartPng(chart.getImageURI());
                }}
              />
            </Grid>
          </Grid>)}

        {gestorSelecionado.equipamento != "ABB" && (
          <Grid item xs={12}>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }} align="left">
              Demanda
            </Typography>{" "}
            <Grid item xs={12}>
              <LineChart
                ref={potenciaChartRef}
                chartType={"LineChart"}
                data={makeDaily(dadosColetas)}
                selected={[gestorSelecionado]}
                chartSize={400}
                isStacked={true}
                slantedText={false}
                breakHourLine={true}
                chartSelecteds={["potenciaD"]}
                filter={false}
                onChartReady={(chart) => {
                  setPotenciaChartPng(chart.getImageURI());
                }}
              />
            </Grid>
          </Grid>)}
      </Grid>

    ) : (
      <></>
    );
  }, [dadosColetas, filters.mostrarRelatorioGerencial]);

  const RelatorioDemandaAnualMemo = useMemo(() => {
    return filters.mostrarRelatorioDemandaAnual && dadosDemandaAnual.length > 0 ? (
      <Grid
        id={RELATORIO_DEMANDA_ANUAL_ID}
        container
        spacing={3}
        style={{ marginTop: 30 }}
      >
        <Grid item xs={12}>
          {" "}
          <Typography style={{ fontSize: 20, fontWeight: "bold" }} align="left">
            Relatório Demanda Anual (Gráfico)
          </Typography>{" "}
        </Grid>
        <Grid container xs={12}>
          <Grid item xs={12} style={{display: "flex", justifyContent: "space-between"}}>
            <Typography style={{ fontSize: 16, fontWeight: "bold" }}>
              Demanda
            </Typography>{" "}
            <FormControl size="small">
              <InputLabel>Ano</InputLabel>
              <Select
                autoWidth
                id="Ano"
                label="Ano"
                value={year}
                name="year"
                onChange={(event) => changeYear(event)}
              >
                {listYears.map((year) => (
                  <MenuItem
                    key={year}
                    value={year}
                  >{`${year}`}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <LineChart
              ref={demandaAnualChartRef}
              chartType={"LineChart"}
              data={dadosDemandaAnual}
              selected={[gestorSelecionado]}
              chartSize={400}
              isStacked={true}
              slantedText={false}
              monthly={true}
              chartSelecteds={["demandaAnual"]}
              filter={false}
              onChartReady={(chart) => {
                setDemandaAnualChartPng(chart.getImageURI());
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    ) : (
      <></>
    );
  }, [dadosDemandaAnual, filters.mostrarRelatorioDemandaAnual]);

  let aneelTableRef = React.createRef();
  let dadosTableRef = React.createRef();
  let economiaTableRef = React.createRef();
  let quedaTableRef = React.createRef();
  let massaTableRef = React.createRef();

  const toolActions = [
    {
      title: "Baixar XLS",
      label: "Baixar",
      color: colors.GREEN,
      MaterialIcon: CloudDownloadRounded,
      handleClick: (e, selected) => {
        e.stopPropagation();
        if (aneelTableRef && aneelTableRef.current) {
          aneelTableRef.current.getExportRowsXls(null, true, false);
        }

        if (dadosTableRef && dadosTableRef.current) {
          dadosTableRef.current.getExportRowsXls(null, true, false);
        }

        if (quedaTableRef && quedaTableRef.current) {
          quedaTableRef.current.getExportRowsXls(null, true, false);
        }

        if (economiaTableRef && economiaTableRef.current) {
          economiaTableRef.current.getExportRowsXls(null, true, false);
        }

        if (massaTableRef && massaTableRef.current) {
          massaTableRef.current.getExportRowsXls(null, true, false);
        }
      },
    },
  ];

  const RelatorioMassaMemo = useMemo(() => {
    return filters.mostrarRelatorioMassa && dadosMassa.length > 0 ? (
      <div id={RELATORIO_MASSA_ID} style={{ marginTop: 30 }}>
        <div style={{ height: "100%", display: "flex", justifyContent: "flex-end" }}>
          <FormControlLabel
            style={{
              backgroundColor: "white",
              paddingRight: "8px",
              borderTop: "1px solid #00000010",
              borderLeft: "1px solid #00000014",
              borderRight: "1px solid #00000014",
              borderRadius: "5px 5px 0px 0px",
            }}
            control={<Switch
              color="default"
              onClick={() => setProdist(!prodist)}
            />}
            label="Prodist modo 8"
          />
        </div>
        <ObjectTable
          orderBy="data_coleta"
          tableName={`Relatório de Massa${prodist ? " (Prodist modo 8)" : ""}`}
          headRows={headRowsRelatorioMassa || []}
          rows={dadosMassa}
          actions={[]}
          selectedToolActions={[]}
          toolActions={toolActions}
          rowSize={40}
          rowsOptions={[40]}
          perPage={40}
          ref={dadosTableRef}
        />
      </div>
    ) : (
      <></>
    );
  }, [dadosMassa, filters.mostrarRelatorioMassa]);

  const RelatorioEconomiaMemo = useMemo(() => {
    const iconHead = [
      {
        MaterialIcon: AttachMoney,
        color: colors.GREEN,
      },
    ];
    return filters.mostrarRelatorioEconomia &&
      dadosRelatorioEconomia.length > 0 ? (
      <div id={RELATORIO_ECONOMIA_ID} style={{ marginTop: 30 }}>
        <ObjectTable
          rowId="date"
          tableName={"Potencial de Economia Diária"}
          materialIconHead={iconHead}
          headRows={headRowsEconomia || []}
          rows={dadosRelatorioEconomia}
          actions={[]}
          selectedToolActions={[]}
          toolActions={toolActions}
          rowSize={40}
          rowsOptions={[40]}
          perPage={40}
          ref={economiaTableRef}
        />
        <Grid
          container
          style={{
            backgroundColor: "#201E29",
            color: "#EFF1D0",
            padding: 10,
            fontSize: 16,
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={6}
            md={6}
            style={{ textAlign: "left", paddingLeft: 10 }}
          >
            Valor total
          </Grid>
          <Grid item xs={6} md={16} style={{ paddingLeft: 10 }}>
            {formatMoney(
              dadosRelatorioEconomia.reduce(
                (acc, curr) => acc + curr?.potencial_economia || 0,
                0
              )
            )}
          </Grid>
        </Grid>
      </div>
    ) : (
      <></>
    );
  }, [dadosRelatorioEconomia, filters.mostrarRelatorioEconomia]);

  const RelatorioDadosMemo = useMemo(() => {
    return filters.mostrarRelatorioDados && dadosColetas.length > 0 ? (
      <div id={RELATORIO_DADOS_ID} style={{ marginTop: 30 }}>
        <ObjectTable
          orderBy="data_coleta"
          order="desc"
          tableName={"Relatório de Dados do Equipamento"}
          headRows={headRowsRelatorioDados || []}
          rows={dadosColetas}
          actions={[]}
          selectedToolActions={[]}
          toolActions={toolActions}
          rowSize={40}
          rowsOptions={[40]}
          perPage={40}
          ref={dadosTableRef}
        />
      </div>
    ) : (
      <></>
    );
  }, [dadosColetas, filters.mostrarRelatorioMassa]);

  const RelatorioQuedaMemo = useMemo(() => {
    return filters.mostrarRelatorioQueda && dadosRelatorioQueda.length > 0 ? (
      <div id={RELATORIO_QUEDA_ID} style={{ marginTop: 30 }}>
        <ObjectTable
          orderBy="offMoment"
          order="desc"
          tableName={"Relatório de queda de energia"}
          headRows={headRowsRelatorioQueda || []}
          rows={dadosRelatorioQueda}
          actions={[]}
          selectedToolActions={[]}
          toolActions={toolActions}
          rowSize={40}
          rowsOptions={[40]}
          perPage={40}
          ref={dadosTableRef}
        />
        <Grid
          container
          style={{
            backgroundColor: "#201E29",
            color: "#EFF1D0",
            padding: 10,
            fontSize: 16,
            display: "flow",
          }}
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid
            item
            xs={6}
            md={6}
            style={{
              textAlign: "left",
              paddingLeft: 10,
              display: "flex",
              justifyContent: "space-around",
              maxWidth: "100%",
            }}
          >
            <div>
              DIC: {tempoTotalOff}
            </div>
            <div>
              FIC: {dadosRelatorioQueda.length}
            </div>
            <div>
              DMIC: {maiorQueda}
            </div>
          </Grid>
        </Grid>
      </div>
    ) : (
      <></>
    );
  }, [dadosRelatorioQueda, tempoTotalOff, filters.mostrarRelatorioQueda]);

  const RelatorioAneelMemo = useMemo(() => {
    let headRows = headRowsRelatorio || [];

    return filters.mostrarRelatorioAneel && dadosRelatorioAneel.length > 0 ? (
      <div id={RELATORIO_ANEEL_ID} style={{ marginTop: 30 }}>
        <ObjectTable
          rowId="id"
          tableName="Relatório Aneel"
          orderBy="data_coleta"
          headRows={headRows}
          rows={dadosRelatorioAneel}
          actions={[]}
          selectedToolActions={[]}
          toolActions={toolActions}
          rowSize={40}
          rowsOptions={[40]}
          perPage={40}
          ref={aneelTableRef}
        />
      </div>
    ) : (
      <></>
    );
  }, [dadosRelatorioAneel, filters.mostrarRelatorioAneel]);

  const RelatorioFechamentoMemo = useMemo(() => {
    return filters.mostrarRelatorioFechamento &&
      Object.keys(dadosRelatorioFechamento).length > 0 ? (
      <div id={RELATORIO_FECHAMENTO_ID} style={{ marginTop: 30 }}>
        <Typography style={{ fontSize: 20, fontWeight: "bold" }} align="left">
          Relatório de Rateio de Consumo
        </Typography>{" "}
        <FechamentoView
          data={dadosRelatorioFechamento}
          gestor={gestorSelecionado}
          dataFinal={filters.dataFinal}
          dataInicial={filters.dataInicial}
        />
      </div>
    ) : (
      <></>
    );
  }, [dadosRelatorioFechamento, filters.mostrarRelatorioFechamento]);

  return (
    <div>
      <RelatorioFiltros
        selected={selected}
        onSubmit={onSubmit}
        onChangeFilters={onChangeFilters}
        gestores={gestores}
      />

      {(Object.keys(dadosRelatorioFatura).length > 0 ||
        Object.keys(dadosRelatorioFechamento).length > 0 ||
        dadosRelatorioEconomia.length > 0 ||
        dadosColetas.length > 0 ||
        dadosRelatorioAneel.length > 0 ||
        dadosDemandaAnual.length > 0) && (
          <Grid container display="flex">
            <Button
              variant="contained"
              style={{
                height: 40,
                backgroundColor: colors.DARK_GREY,
                color: "white",
                marginTop: 14,
                boxShadow: "none"
              }}
              onClick={printRelatorio}
            >
              Exportar <PictureAsPdf style={{ marginLeft: 7.5 }} />
            </Button>
          </Grid>
        )}

      {RelatorioFaturaMemo}
      {RelatorioFechamentoMemo}
      {RelatorioGerencialMemo}
      {RelatorioEconomiaMemo}
      {RelatorioMassaMemo}
      {RelatorioAneelMemo}
      {RelatorioDadosMemo}
      {RelatorioQuedaMemo}
      {RelatorioDemandaAnualMemo}

    </div>
  );
}

export default Relatorios;
